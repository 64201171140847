import "./choice_matrix.scss";
import deleteImg from "images/delete.svg";

import React from "react";

import _ from "lodash";
import classnames from "classnames";

import api from "util/api.js";
import Button from "components/Button/Button.js";
import Select from "components/Select/Select.js";
import Input from "components/Input/Input.js";

class ChoiceMatrix extends React.Component {
  constructor(props) {
    super(props);

    let choices = props.message.choices
      ? props.message.choices.map(c => ({
          ...c,
          new: false,
          modified: false,
          deleted: false,
        }))
      : [];

    if (props.message.shuffle_choices === 0) {
      // Order by their natural IDs, otherwise they will be alphabetically
      // ordered server-side.
      console.log(`sorting choices by ID`);
      choices = _.sortBy(choices, "id");
    }

    this.state = {
      markerId: null, // The selected marker.
      submarkers: [], // All the submarkers.
      markers: [], // All the markers.
      messages: [], // All the messages.
      messageSubmarkerIds: [], // Submarkers associated with this message.
      choiceSubmarkers: [], // The choice / submarker values.
      editingChoiceIdx: null,
      editingChoiceValue: "",
      modifiedChoices: {},
      errorMessage: null,
      // Track which choices have changed, save them when necessary.
      choices,
    };
  }

  componentWillMount() {
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.body.style.overflow = null;
  }

  componentDidMount = async () => {
    const [
      { data: protuMarkers },
      { data: choiceSubmarkers },
      { data: messages },
    ] = await Promise.all([
      api.get(`/admin/submarkers`),
      api.get(`/admin/choice-submarkers/${this.props.message.id}`),
      api.get(`/admin/messages`),
    ]);

    // FIXME: hardcoding to use the protu markers at index 0 for now
    const submarkers = _(protuMarkers[0].markers)
      .map("submarkers")
      .flatten()
      .value();

    this.setState({
      markerId: protuMarkers[0].markers[0].id,
      choiceSubmarkers,
      submarkers,
      markers: protuMarkers[0].markers,
      messages,
    });
  };

  getSubmarkers() {
    return _.filter(this.state.submarkers, { marker_id: this.state.markerId });
  }

  handleMarkerChange = event => {
    this.setState({ markerId: parseInt(event.target.value, 10) });
  };

  updateChoiceSubmarker = (choiceId, submarkerId, value) => {
    const choiceSubmarkers = _.clone(this.state.choiceSubmarkers);
    const c = _.find(choiceSubmarkers, {
      choice_id: choiceId,
      submarker_id: submarkerId,
    });

    if (c) {
      c.value = value;
    } else {
      choiceSubmarkers.push({
        choice_id: choiceId,
        submarker_id: submarkerId,
        value,
      });
    }

    this.setState({ choiceSubmarkers }, () => {
      this.checkError();
    });
  };

  checkError() {
    const hasError = _.find(this.state.choiceSubmarkers, c =>
      this.isInvalidValue(c.value),
    );

    if (hasError) {
      this.setState({
        errorMessage: "All values must be within the range -1.0 to 1.0.",
      });
      return true;
    }

    this.setState({ errorMessage: null });
    return false;
  }

  save = async () => {
    if (this.state.editingChoiceIdx) {
      alert("Please finish editing the current choice before saving.");
      return;
    }

    // Update choices.
    for (let choice of this.state.choices) {
      if (choice.deleted && choice.new === false) {
        await api.delete(`/admin/choice/${choice.id}`);
        continue;
      }

      if (choice.new) {
        const tmpChoiceId = choice.id;

        // Do not POST with an ID, or it will be treated as a new Choice.
        choice.id = undefined;
        const {
          data: { choice: newChoice },
        } = await api.post(`/admin/choice`, choice);

        // Replace the temporary choice_submarker IDs with real ones.
        await this.setState({
          choiceSubmarkers: this.state.choiceSubmarkers.map(cs =>
            cs.choice_id === tmpChoiceId
              ? { ...cs, choice_id: newChoice.id }
              : cs,
          ),
        });
        continue;
      }

      if (choice.modified) {
        // Replace the temporary choice_submarker IDs with real ones.
        await api.post(`/admin/choice`, choice);
      }
    }

    const choiceSubmarkers = this.state.choiceSubmarkers;
    await api.post(`/admin/choice-submarkers`, { choiceSubmarkers });
    this.props.closeModal();
  };

  isInvalidValue(v) {
    if (v === "") {
      return false;
    }

    const floatVal = parseFloat(v);
    if (isNaN(floatVal)) {
      return true;
    }

    if (floatVal > 1 || floatVal < -1) {
      return true;
    }

    return false;
  }

  isValidValue(v) {
    if (v === "") {
      return false;
    }

    return !this.isInvalidValue(v);
  }

  isEditingChoice(choiceIdx) {
    return this.state.editingChoiceIdx === choiceIdx;
  }

  setEditingChoiceIdx = choiceIdx => {
    let p;
    if (this.state.editingChoiceIdx !== null) {
      p = this.finishEditingChoiceValue();
    } else {
      p = Promise.resolve();
    }

    p.then(() => {
      this.setState({
        editingChoiceIdx: choiceIdx,
        editingChoiceValue: this.state.choices[choiceIdx].choice,
      });
    });
  };

  handleChoiceKeyDown = e => {
    if (e.key === "Enter") {
      // Do not submit the form.
      e.preventDefault();
      this.setState({
        editingChoiceIdx: null,
        editingChoiceValue: null,
        choices: this.state.choices.map((c, idx) =>
          idx === this.state.editingChoiceIdx
            ? { ...c, choice: this.state.editingChoiceValue, modified: true }
            : c,
        ),
      });
    }
  };

  deleteChoice = choiceIdx => {
    this.setState({
      editingChoiceIdx: null,
      editingChoiceValue: null,
      choices: this.state.choices.map((c, idx) =>
        idx === choiceIdx ? { ...c, deleted: true } : c,
      ),
    });
  };

  handleNewChoice() {
    this.setState(
      {
        choices: [
          ...this.state.choices,
          {
            id: new Date().getTime(), // Use a temporary ID.
            message_id: this.props.message.id,
            choice: "",
            deleted: false,
            modified: false,
            new: true,
          },
        ],
      },
      () => {
        this.setState({
          editingChoiceIdx: this.state.choices.length - 1,
        });
      },
    );
  }

  getChoiceSubmarkerValue(choice_id, submarker_id) {
    const submarker = _.find(this.state.choiceSubmarkers, {
      choice_id,
      submarker_id,
    });

    return _.get(submarker, "value", "");
  }

  updateChoice = (choice, event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      choices: this.state.choices.map(c =>
        c.id === choice.id ? { ...c, [name]: value, modified: true } : c,
      ),
    });
  };

  ChoiceRow = ({ choice, idx }) => {
    if (choice.deleted) {
      return null;
    }

    return (
      <tr key={idx}>
        <th>
          {this.isEditingChoice(idx) ? (
            <span>
              <img
                className="choice_matrix__delete_choice"
                alt="delete choice"
                src={deleteImg}
                onClick={() => this.deleteChoice(idx)}
              />
              <Input
                defaultValue={choice.choice}
                onKeyDown={this.handleChoiceKeyDown}
                onChange={e =>
                  this.setState({
                    editingChoiceValue: e.target.value,
                  })
                }
                autoFocus
                inline
              />
            </span>
          ) : (
            <span onClick={() => this.setEditingChoiceIdx(idx)}>
              {choice.choice}
            </span>
          )}
        </th>
        {this.getSubmarkers().map(sm => (
          <td className="choice_matrix__submarker-value-cell" key={sm.id}>
            <input
              type="number"
              min="-1.00"
              max="1.00"
              step="0.01"
              className={classnames("choice_matrix__choice-submarker-value", {
                "valid-value": this.isValidValue(
                  this.getChoiceSubmarkerValue(choice.id, sm.id),
                ),
                "invalid-value": this.isInvalidValue(
                  this.getChoiceSubmarkerValue(choice.id, sm.id),
                ),
              })}
              value={this.getChoiceSubmarkerValue(choice.id, sm.id)}
              onChange={e =>
                this.updateChoiceSubmarker(choice.id, sm.id, e.target.value)
              }
            />
          </td>
        ))}
        {this.props.message.type === "single choice" && (
          <td>
            <select
              className="admin-input"
              name="next_message_id"
              value={choice.next_message_id}
              onChange={e => this.updateChoice(choice, e)}
            >
              <option>Default (next message)</option>
              {this.state.messages.map(m => (
                <option key={m.id} value={m.id}>
                  {m.text}
                </option>
              ))}
            </select>
          </td>
        )}
      </tr>
    );
  };

  render() {
    return (
      <div className="choice_matrix">
        <div>
          {this.getSubmarkers().length > 0 ? (
            <Select
              label="Marker"
              name="marker"
              value={this.state.markerId.toString()}
              onChange={this.handleMarkerChange}
              options={this.state.markers.map(marker => ({
                value: marker.id,
                text: marker.name,
              }))}
              inline
            />
          ) : null}

          <Button
            text="Close"
            onClick={this.props.closeModal}
            width="narrow"
            variant="cancel"
            inline
          />

          <Button
            text="Save"
            onClick={this.save}
            disabled={this.state.errorMessage !== null}
            width="narrow"
            variant="save"
            inline
          />

          <div className="error-message align-right">
            {this.state.errorMessage}
          </div>

          <hr />
          {this.props.message.text}
          <table>
            <thead>
              <tr>
                <th className="vertical-text" />
                {this.getSubmarkers().map(sm => (
                  <th key={sm.id}>
                    <span className="choice_matrix__submarker-title">
                      {sm.name}
                    </span>
                  </th>
                ))}
                {this.props.message.type === "single choice" && (
                  <th>Next Message</th>
                )}
              </tr>
            </thead>
            <tbody>
              {this.state.choices.map((c, i) => (
                <this.ChoiceRow key={c.id} choice={c} idx={i} />
              ))}
              <tr key={this.state.choices.length + 1}>
                <th className="choices align-right">
                  {this.state.editingChoiceIdx === null ? (
                    <Button
                      text="New Choice"
                      variant="action"
                      onClick={this.handleNewChoice.bind(this)}
                      width="fill"
                    />
                  ) : null}
                </th>
                <td colSpan={this.getSubmarkers().length} />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ChoiceMatrix;
