import { Component } from "react";
import _ from "lodash";
import "./team_output.scss";
import api from "util/api.js";
import handle_input_change from "util/handle_input_change.js";
import currentUser from "util/currentUser.js";
import { BOT_ID } from "util/constants.js";

class BaseTeamOutput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      current_company_id: "",
      current_team_id: "",
      current_user_id: "",
      markers: [],
      submarkers: [],
      submarker_scores: null,
    };

    this.handle_input_change = handle_input_change.bind(this);

    // How many submarkers to show in top and bottom 'performance indicators'.
    this.num_submarkers = 5;
  }

  componentDidMount = async () => {
    const [{ data: companies }, { data: markers }] = await Promise.all([
      api.get("/admin/companies"),
      api.get("/admin/markers"),
    ]);

    const initial_company_id = currentUser.company_id || companies[0].id;

    // Index the markers and submarkers on their ID.
    // TODO: Might be sensible if this was done server-side.
    this.setState({
      companies,
      current_company_id: initial_company_id,
      markers: _.keyBy(markers, "id"),
      submarkers: _(markers)
        .flatMap("submarkers")
        .keyBy("id")
        .value(),
    });

    const { data: submarker_scores } = await api.get(
      `/admin/company/${initial_company_id}/submarker-scores`,
    );

    this.setState({
      submarker_scores: submarker_scores,
    });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.current_company_id !== prevState.current_company_id) {
      this.setState({
        submarker_scores: null,
        current_user_id: "",
        current_team_id: "",
      });

      const { data: submarker_scores } = await api.get(
        `/admin/company/${this.state.current_company_id}/submarker-scores`,
      );

      this.setState({
        submarker_scores: submarker_scores,
      });
    }

    // If the previously selected user is no longer in the new team, reset the
    // user selection.
    if (
      this.state.current_team_id !== prevState.current_team_id &&
      this.get_current_team() &&
      _.find(this.get_team_users(), {
        id: parseInt(this.state.current_user_id),
      }) === undefined
    ) {
      this.setState({ current_user_id: "" });
    }
  };

  get_current_company = () => {
    return _.find(this.state.companies, {
      id: parseInt(this.state.current_company_id),
    });
  };

  get_current_team = () => {
    return _.find(this.get_current_company().teams, {
      id: parseInt(this.state.current_team_id),
    });
  };

  get_company_users = () => {
    return this.get_current_company().users;
  };

  get_team_users = () => {
    return this.get_current_company().users.filter(
      user => user.team_id === parseInt(this.state.current_team_id),
    );
  };

  get_member_choices = () => {
    const companyUsers = this.get_company_users();
    const team = this.get_current_team();

    let choices = companyUsers;

    if (team) {
      choices = choices.filter(user => user.team_id === team.id);
    }

    // Only show members that have completed the bot.
    choices = choices.filter(user =>
      user.bots_progress.find(
        bot_progress =>
          bot_progress.bot_id === BOT_ID && bot_progress.progress === 100,
      ),
    );
    return choices;
  };

  get_current_member = () => {
    return _.find(this.get_company_users(), {
      id: parseInt(this.state.current_user_id),
    });
  };

  get_group_name = () => {
    const team = this.get_current_team();
    if (team) {
      return team.name;
    }

    return this.get_current_company().name;
  };

  get_current_focus = () => {
    if (this.get_current_member()) {
      return this.get_current_member();
    }

    if (this.get_current_team()) {
      return this.get_current_team();
    }

    return this.get_current_company();
  };

  get_current_focus_name = () => {
    return this.get_current_focus().name;
  };

  get_current_focus_submarker_scores = () => {
    if (this.get_current_member()) {
      return this.state.submarker_scores
        .filter(
          user_score => user_score.user_id === this.get_current_member().id,
        )
        .flatMap(user_score => user_score.submarker_scores);
    }

    if (this.get_current_team()) {
      return this.state.submarker_scores
        .filter(user_score => user_score.team_id === this.get_current_team().id)
        .flatMap(user_score => user_score.submarker_scores);
    }

    return this.state.submarker_scores.flatMap(user => user.submarker_scores);
  };

  /**
   * Get the submarker scores (averaged across the users in the group) for the
   * currently focused member / team / company.
   */
  get_current_focus_avg_submarker_scores = () => {
    return _(this.get_current_focus_submarker_scores())
      .groupBy("submarker_id")
      .map((submarkerScores, submarkerId) => {
        return {
          ...this.state.submarkers[submarkerId],
          marker: this.state.markers[
            this.state.submarkers[submarkerId].marker_id
          ],
          submarker_id: submarkerId,
          score: _.sumBy(submarkerScores, "score") / submarkerScores.length,
        };
      })
      .sortBy("score")
      .value();
  };

  /**
   * Get the top submarker scores (averaged across the users in the group) for
   * the focused member / team / company.
   */
  get_current_focus_top_avg_submarker_scores = () => {
    return this.get_current_focus_avg_submarker_scores()
      .slice(this.num_submarkers)
      .reverse();
  };

  /**
   * Get the bottom submarker scores (averaged across the users in the group) for
   * the focused member / team / company.
   */
  get_current_focus_bottom_avg_submarker_scores = () => {
    return this.get_current_focus_avg_submarker_scores()
      .slice(0, this.num_submarkers)
      .reverse();
  };

  /**
   * Return the marker scores (calculated by averaging marker's submarker
   * scores) for a given user.
   */
  get_user_marker_scores = user_id => {
    const userScores = _.find(this.state.submarker_scores, { user_id })
      .submarker_scores;

    return _(userScores)
      .map(submarkerScore => ({
        ...submarkerScore,
        marker_id: this.state.submarkers[submarkerScore.submarker_id].marker_id,
      }))
      .groupBy("marker_id")
      .flatMap((submarkerScores, marker_id) => ({
        ...this.state.markers[marker_id],
        score: _.sumBy(submarkerScores, "score") / submarkerScores.length,
      }))
      .value();
  };

  /**
   * If a team is selected, return the team's scores.
   * If a team is not selected, return the company's scores.
   */
  get_group_submarker_scores = () => {
    if (this.state.current_team_id !== "") {
      // Team scores.
      return _.filter(this.state.submarker_scores, {
        team_id: parseInt(this.state.current_team_id),
      });
    }

    // Company scores.
    return this.state.submarker_scores;
  };

  /**
   * For each marker, return the top scoring user for the marker.
   * If a team is selected, return the top scorer in the team.
   * If a team is not selected, return the top scorer in the company.
   */
  get_group_top_marker_scores = () => {
    const userMarkerScores = this.get_group_submarker_scores()
      .map(userSubmarkerScores => ({
        ...userSubmarkerScores,
        marker_scores: this.get_user_marker_scores(
          userSubmarkerScores.user_id,
        ).map(markerScore => ({
          ...markerScore,
          user_id: userSubmarkerScores.user_id,
        })),
      }))
      .flatMap(userSubmarkerScores => userSubmarkerScores.marker_scores);

    return Object.values(this.state.markers)
      .map(marker => {
        const topScorer = _(userMarkerScores)
          .filter({ name: marker.name })
          .sortBy("score")
          .last();

        if (topScorer) {
          return {
            ...marker,
            user_id: topScorer.user_id,
            score: topScorer.score,
          };
        }

        // Nobody on the team has a score for that marker.
        return undefined;
      })
      .filter(markerScore => markerScore !== undefined);
  };

  get_radar_chart_data = () => {
    const groupData = _(this.get_group_top_marker_scores())
      .map(({ name: axis, score: value }) => ({
        axis,
        value,
        showDataCircle: false,
      }))
      .sortBy("axis")
      .value();

    let radarChartData = [groupData];

    if (this.state.current_user_id !== "") {
      radarChartData.push(
        _(this.get_user_marker_scores(parseInt(this.state.current_user_id)))
          .map(({ name: axis, score: value }) => ({
            axis,
            value,
            showDataCircle: (() => {
              const topMarkerScore = _.find(
                this.get_group_top_marker_scores(),
                { name: axis },
              );
              if (topMarkerScore === undefined) {
                return false;
              }

              return (
                topMarkerScore.user_id === parseInt(this.state.current_user_id)
              );
            })(),
          }))
          .sortBy("axis") // The order appears to matter.
          .value(),
      );
    } else {
      radarChartData.push([]);
    }

    return radarChartData;
  };
}

export default BaseTeamOutput;
