import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import BotButton from "components/BotButton/BotButton.js";

class BotMessageExplain extends Component {
  constructor(props) {
    super(props);

    this.state = { visible: true };
  }

  show_explanation = () => {
    this.props.show_explanation();

    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <CSSTransition
        in={this.state.visible}
        appear={true}
        timeout={400}
        classNames={{
          appear: "from_right--enter",
          appearActive: "from_right--enter_active",
          enter: "",
          enterActive: "",
          enterDone: "from_right--enter_done",
          exit: "to_right--exit",
          exitActive: "to_right--exit_active",
          exitDone: "",
        }}
        unmountOnExit={true}
      >
        <BotButton
          colour="navy"
          variant="hollow"
          size="small"
          text="?"
          on_click={this.show_explanation}
        />
      </CSSTransition>
    );
  }
}

BotMessageExplain.propTypes = {
  show_explanation: PropTypes.func.isRequired,
};

export default BotMessageExplain;
