import "./component_test.scss";

import React from "react";
import Input from "components/Input/Input.js";
import Select from "components/Select/Select.js";
import TextArea from "components/TextArea/TextArea.js";
import Button from "components/Button/Button.js";

class ComponentTest extends React.Component {
  render() {
    return (
      <div
        className="component_test"
        style={{ backgroundColor: "white", width: "460px", height: "100%" }}
      >
        <Input name="narrow_input" label="Narrow input" width="narrow" />
        <Input name="medium_input" label="Medium input" width="medium" />
        <Input name="wide_input" label="Wide input" width="wide" />
        <Input name="fill_input" label="Fill input" width="fill" />

        <Select
          name="narrow_select"
          label="Narrow Select with simple options"
          width="narrow"
          options={["Cake", "Pie", "Chips"]}
        />
        <Select
          name="medium_select"
          label="Medium Select with value / text options"
          width="medium"
          options={[
            { value: 1, text: "Cake" },
            { value: 2, text: "Pie" },
            { value: 3, text: "Chips" },
          ]}
        />

        <TextArea
          name="fill_text_area"
          label="Fill Text Area"
          width="fill"
          value="This is some text"
        />

        <Button
          name="medium_button"
          label="Medium Button"
          width="medium"
          text="Unclickable"
        />

        <Button
          name="medium_action_button"
          label="Medium Action Button"
          width="medium"
          text="Action"
          variant="action"
        />
      </div>
    );
  }
}

export default ComponentTest;
