import React from "react";

import api from "util/api.js";
import FeedbackRow from "old-components/FeedbackRow/FeedbackRow.js";

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbacks: [],
    };
  }

  componentDidMount() {
    this.getFeedbacks();
  }

  async getFeedbacks() {
    const { data: feedbacks } = await api.get(`/user/feedback`);
    this.setState({ feedbacks });
  }

  render() {
    if (this.state.feedbacks.length === 0) {
      return <div>There is no feedback!</div>;
    }

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Feedback Message</th>
              <th style={{ width: "5%" }}>
                I don&apos;t understand the question
              </th>
              <th style={{ width: "5%" }}>
                The answers don&apos;t apply to me
              </th>
              <th style={{ width: "5%" }}>I want to choose more answers</th>
              <th style={{ width: "5%" }}>The question could be better</th>
              <th>Free text responses</th>
            </tr>
          </thead>

          <tbody>
            {this.state.feedbacks.map((feedback, idx) => (
              <tr key={idx}>
                <FeedbackRow feedback={feedback} />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Feedback;
