import React from "react";
import cn from "classnames";

import "./select.scss";
import PropTypes from "prop-types";

class Select extends React.Component {
  render() {
    return (
      <div
        className={cn("select", {
          "select--width_narrow": this.props.width === "narrow",
          "select--width_medium": this.props.width === "medium",
          "select--width_wide": this.props.width === "wide",
          "select--width_fill": this.props.width === "fill",
          "select--inline": this.props.inline,
        })}
      >
        <div className="select__label-container">
          {this.props.label && (
            <label className="select__label" htmlFor={this.props.name}>
              {this.props.label}
            </label>
          )}
        </div>
        <select
          className="select__field"
          type={this.props.type}
          name={this.props.name}
          value={this.props.value}
          placeholder={this.props.placeholder}
          readOnly={this.props.readonly}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
        >
          {this.props.options.map(o => {
            if (typeof o === "string") {
              return (
                <option key={o} value={o}>
                  {o}
                </option>
              );
            }

            return (
              <option key={o.value} value={o.value}>
                {o.text}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

Select.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(["text", "number"]).isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  width: PropTypes.oneOf(["narrow", "medium", "wide", "fill"]),
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
};

Select.defaultProps = {
  type: "text",
  width: "medium",
  readonly: false,
  diabled: false,
  inline: false,
};

export default Select;
