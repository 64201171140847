import "./edit_message.scss";

import React from "react";

import _ from "lodash";

import Input from "components/Input/Input.js";
import Select from "components/Select/Select.js";
import TextArea from "components/TextArea/TextArea.js";
import Button from "components/Button/Button.js";

import ChoiceMatrix from "old-components/ChoiceMatrix/ChoiceMatrix.js";
import { messageTypes } from "util/constants.js";
import api from "util/api.js";

class EditMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChoiceMatrixOpen: false,
      isModified: false,
      suggestionLists: [],
    };
  }

  componentWillMount = async () => {
    const { data: suggestionLists } = await api.get("/admin/suggestion-lists");
    this.setState({ suggestionLists });
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const message = Object.assign({}, this.props.message, { [name]: value });
    this.setState({ isModified: true });
    this.props.onMessageChange(message);
  };

  confirmClose() {
    let shouldClose = true;
    if (this.state.isModified === true) {
      shouldClose = window.confirm(
        "The message has been modified. Really close without saving?",
      );
    }

    if (shouldClose) {
      this.props.close();
    }
  }

  render() {
    const showChoices = ["single choice", "multiple choice"].includes(
      this.props.message.type,
    );

    return (
      <div className="edit_message">
        <div>
          {this.state.isChoiceMatrixOpen && (
            <ChoiceMatrix
              message={this.props.message}
              closeModal={() => {
                this.props.refreshEditingMessage();
                this.setState({ isChoiceMatrixOpen: false });
              }}
            />
          )}

          <div className="edit_message__controls">
            <Button
              text="Delete"
              onClick={this.props.delete}
              variant="delete"
              width="narrow"
              inline
            />
            <Button
              text="Close"
              onClick={this.confirmClose.bind(this)}
              variant="cancel"
              width="narrow"
              inline
            />
            <Button
              text="Save"
              onClick={() => this.props.save()}
              variant="save"
              width="narrow"
              inline
            />
          </div>

          <div
            className="edit_message__message_order"
            title={`Message ID=${this.props.message.id}`}
          >
            #{this.props.message.order}
          </div>

          <div className="edit_message__input-group">
            <TextArea
              label="Text"
              name="text"
              onChange={this.handleInputChange}
              value={this.props.message.text}
            />
          </div>

          <div className="edit_message__input-group">
            <Input
              label="Explanation"
              name="explanation"
              value={this.props.message.explanation}
              onChange={this.handleInputChange}
              width="fill"
            />
          </div>

          {/* Message type and related inputs */}
          <div className="edit_message__input-group edit_message__input-group-inline">
            <Select
              label="Type"
              name="type"
              value={this.props.message.type}
              onChange={this.handleInputChange}
              options={messageTypes}
              inline
            />

            {["search", "multiple choice"].includes(
              this.props.message.type,
            ) && (
              <Input
                label="Maximum Choices"
                name="maximum_choices"
                value={this.props.message.maximum_choices}
                onChange={this.handleInputChange}
                inline
              />
            )}

            {["single choice", "multiple choice"].includes(
              this.props.message.type,
            ) && (
              <Select
                label="Shuffle Choices"
                name="shuffle_choices"
                value={this.props.message.shuffle_choices}
                onChange={this.handleInputChange}
                options={[
                  { value: "1", text: "Yes" },
                  { value: "0", text: "No" },
                ]}
                inline
              />
            )}

            {/* TODO Try to fix the spacing between this and the above row. */}
            {this.props.message.type === "search" && (
              <Select
                label="Search Suggestions"
                name="suggestion_list_id"
                value={
                  this.props.message.suggestion_list_id
                    ? this.props.message.suggestion_list_id.toString()
                    : ""
                }
                onChange={this.handleInputChange}
                options={[
                  { value: "", text: "None" },
                  ...this.state.suggestionLists.map(suggestionList => ({
                    value: suggestionList.id,
                    text: suggestionList.name,
                  })),
                ]}
                inline
              />
            )}
          </div>
          {/* End message type and related inputs */}

          <div className="edit_message__input-group edit_message__input-group-inline">
            <Input
              label="Section"
              name="section"
              value={this.props.message.section}
              onChange={this.handleInputChange}
              inline
            />
            <Input
              label="Super Section"
              name="super_section"
              value={this.props.message.super_section || ""}
              onChange={this.handleInputChange}
              inline
            />
          </div>

          <div className="edit_message__input-group">
            <Input
              label="Theory"
              name="theory"
              value={this.props.message.theory || ""}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="edit_message__input-group edit_message__input-group-inline">
            {this.props.message.type !== "conversational" && (
              <Select
                label="Can be skipped"
                name="has_dont_know"
                value={this.props.message.has_dont_know}
                onChange={this.handleInputChange}
                options={["Yes", "No"]}
                inline
              />
            )}

            {this.props.message.has_dont_know === "Yes" && (
              <Select
                label="Skip Message goes to"
                name="dont_know_next_message_id"
                value={this.props.message.dont_know_next_message_id || ""}
                onChange={this.handleInputChange}
                options={[
                  { value: "", text: "Default (next message)" },
                  ...this.props.messages.map(message => ({
                    value: message.id,
                    text: message.text,
                  })),
                ]}
              />
            )}
          </div>

          {this.props.message.type !== "conversational" && (
            <div className="edit_message__input-group">
              <label htmlFor="run_function_on_answer">
                Run function on answer
              </label>
              <Select
                name="run_function_on_answer"
                value={this.props.message.run_function_on_answer || ""}
                onChange={this.handleInputChange}
                options={[
                  { value: "", text: "Not Set" },
                  { value: "save_user_name", text: "Save as user's Name" },
                ]}
              />
            </div>
          )}

          {showChoices && (
            <>
              <hr />
              <div>
                <h2>Choices</h2>
              </div>
              <div className="edit_message__input-group">
                {_.get(this.props.message, "choice", [])
                  .map(c => c.choice)
                  .join(", ")}
                <Button
                  text="Edit Choices"
                  variant="action"
                  onClick={() => this.setState({ isChoiceMatrixOpen: true })}
                />
              </div>

              <div className="edit_message__input-group">
                <Select
                  label="Special UI"
                  name="subtype"
                  value={this.props.message.subtype || ""}
                  onChange={this.handleInputChange}
                  options={[
                    { value: "", text: "Standard UI" },
                    { value: "Card", text: "Card UI" },
                  ]}
                />
              </div>

              <div className="edit_message__input-group">
                <Select
                  label="Choices from previous answer"
                  name="generated_choices_message_id"
                  value={this.props.message.generated_choices_message_id || ""}
                  onChange={this.handleInputChange}
                  options={[
                    { value: "", text: "No" },
                    ...this.props.messages.map(message => ({
                      value: message.id,
                      text: message.text,
                    })),
                  ]}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default EditMessage;
