import React from "react";
import cn from "classnames";

import "./text_area.scss";
import PropTypes from "prop-types";

class TextArea extends React.Component {
  render() {
    return (
      <div
        className={cn("select", {
          "text_area--width_narrow": this.props.width === "narrow",
          "text_area--width_medium": this.props.width === "medium",
          "text_area--width_wide": this.props.width === "wide",
          "text_area--width_fill": this.props.width === "fill",
        })}
      >
        <div className="text_area__label-container">
          {this.props.label && (
            <label className="text_area__label" htmlFor={this.props.name}>
              {this.props.label}
            </label>
          )}
        </div>
        <textarea
          className="text_area__field"
          type={this.props.type}
          name={this.props.name}
          placeholder={this.props.placeholder}
          readOnly={this.props.readonly}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          rows="4"
          defaultValue={this.props.value}
        />
      </div>
    );
  }
}

TextArea.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(["text", "number"]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  width: PropTypes.oneOf(["narrow", "medium", "wide", "fill"]),
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  type: "text",
  width: "fill",
  readonly: false,
  diabled: false,
};

export default TextArea;
