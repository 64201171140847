import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import requiredIf from "react-required-if";
import "./bot_user_form.scss";

class BotUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input_value: "",
    };

    this.input_ref = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search_term !== null && this.props.search_term === null) {
      this.setState({
        input_value: "",
      });
    }
  }

  submit_value = event => {
    event.preventDefault();

    this.input_ref.current.blur();

    if (this.props.on_submit && this.state.input_value) {
      this.props.on_submit(this.state.input_value);
    }
  };

  update_value = event => {
    let new_value = event.target.value.trimStart();

    this.setState({
      input_value: new_value,
    });

    if (this.props.on_change) {
      this.props.on_change(new_value);
    }
  };

  render() {
    return (
      <form className="bot__user_input_form" onSubmit={this.submit_value}>
        <CSSTransition
          timeout={800}
          in={this.props.is_input_visible && !this.props.is_disabled}
          classNames={{
            appear: "",
            appearActive: "",
            enter: "from_below--enter",
            enterActive: "from_below--enter_active",
            enterDone: "from_below--enter_done",
            exit: "",
            exitActive: "to_below--exit_active",
            exitDone: "to_below--exit_done",
          }}
        >
          <input
            type="text"
            className="bot__user_input_field from_below--enter"
            value={this.state.input_value}
            onChange={this.update_value}
            disabled={this.props.is_disabled}
            ref={this.input_ref}
          />
        </CSSTransition>
      </form>
    );
  }
}

BotUserForm.propTypes = {
  search_term: PropTypes.string,
  is_input_visible: PropTypes.bool.isRequired,
  on_change: requiredIf(
    PropTypes.func,
    props => !props.hasOwnProperty("on_submit"),
  ),
  on_submit: requiredIf(
    PropTypes.func,
    props => !props.hasOwnProperty("on_change"),
  ),
  is_disabled: PropTypes.bool,
};

BotUserForm.defaultProps = {
  is_disabled: false,
};

export default BotUserForm;
