import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import SVG from "react-inlinesvg";
import characterDefault from "images/protu-characters/protu-character-neutral.svg";
import characterTalkingClosed from "images/protu-characters/talking-2.svg";
import characterExcited from "images/protu-characters/happy-both-hands-up.svg";
import "./protu_character.scss";

class ProtuCharacter extends Component {
  render() {
    return (
      <figure
        className={cn("protu_character", {
          "protu_character--is_default":
            !this.props.is_loading && !this.props.is_submitting,
          "protu_character--is_loading": this.props.is_loading,
          "protu_character--is_submitting": this.props.is_submitting,
        })}
      >
        <SVG
          src={characterDefault}
          className="protu_character__character protu_character__character--default"
        />

        <SVG
          src={characterTalkingClosed}
          className="protu_character__character protu_character__character--talking_closed"
        />

        <SVG
          src={characterExcited}
          className="protu_character__character protu_character__character--excited"
        />
      </figure>
    );
  }
}

ProtuCharacter.propTypes = {
  is_loading: PropTypes.bool.isRequired,
  is_submitting: PropTypes.bool.isRequired,
};

export default ProtuCharacter;
