import React, { Component } from "react";
import SVG from "react-inlinesvg";
import "./icon.scss";
import cn from "classnames";
import PropTypes from "prop-types";

class Icon extends Component {
  render() {
    return (
      <SVG
        src={this.props.src}
        className={cn("iconography", `iconography--${this.props.size}`, {
          [`iconography--rotate_${this.props.rotate}`]: this.props.rotate,
          [`iconography--colour_${this.props.colour}`]: this.props.colour,
        })}
      />
    );
  }
}

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.oneOf([12, 18, 24, 32, 36]),
  rotate: PropTypes.oneOf([90, 180, 270]),
  colour: PropTypes.oneOf(["white"]),
};

Icon.defaultProps = {
  size: 32,
};

export default Icon;
