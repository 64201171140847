import React, { Component } from "react";
import BotButton from "components/BotButton/BotButton.js";
import removeSVG from "../../images/remove.svg";
import PropTypes from "prop-types";
import requiredIf from "react-required-if";

class BotSelectedChoice extends Component {
  deselect_choice = () => {
    this.props.deselect_choice(this.props.choice.id);
  };

  render() {
    return (
      <BotButton
        colour="purple_dark"
        variant="solid"
        text={this.props.choice.choice}
        on_mount_emit_ref={this.props.on_mount_emit_ref}
        on_click={this.props.is_deselectable ? this.deselect_choice : undefined}
        on_unmount_delete_ref={this.props.on_unmount_delete_ref}
        choice_id={this.props.choice.id}
        icon={this.props.is_deselectable ? removeSVG : undefined}
        icon_position="right"
      />
    );
  }
}

BotSelectedChoice.propTypes = {
  choice: PropTypes.object.isRequired,
  on_mount_emit_ref: PropTypes.func.isRequired,
  is_deselectable: PropTypes.bool.isRequired,
  deselect_choice: requiredIf(PropTypes.func, props => props.is_deselectable),
  on_unmount_delete_ref: PropTypes.func.isRequired,
};

export default BotSelectedChoice;
