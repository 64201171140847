import "./protu.scss";
import "./admin.scss";

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { AuthConsumer, AuthProvider } from "react-check-auth";

import Feedback from "old-components/Feedback/Feedback.js";
import AdminLogin from "components/AdminLogin/AdminLogin.js";
import Messages from "old-components/Messages/Messages.js";
import MobileSimulator from "old-components/MobileSimulator/MobileSimulator.js";
import Simulator from "old-components/Simulator/Simulator.js";
import Submarkers from "old-components/Submarkers/Submarkers.js";
import Users from "old-components/Users/Users.js";
import ComponentTest from "components/ComponentTest/ComponentTest.js";
import Correlations from "old-components/Correlations/Correlations.js";
import ComposeBot from "old-components/ComposeBot/ComposeBot.js";
import AdminTeamOutput from "components/TeamOutput/AdminTeamOutput.js";
import LinkSignUp from "old-components/LinkSignUp/LinkSignUp.js";

class LoggedIn extends React.Component {
  render() {
    const links = [
      {
        to: "/submarkers",
        text: "Submarkers",
        component: Submarkers,
      },
      {
        to: "/messages",
        text: "Messages",
        component: Messages,
      },
      {
        to: "/simulator",
        text: "Simulator",
        component: Simulator,
      },
      {
        to: "/mobile-simulator",
        text: "Mobile Simulator",
        component: MobileSimulator,
      },
      {
        to: "/users",
        text: "Users",
        component: Users,
      },
      {
        to: "/feedback",
        text: "Feedback",
        component: Feedback,
      },
      {
        to: "/component-test",
        text: "Components",
        component: ComponentTest,
      },
      {
        to: "/correlations",
        text: "Correlations",
        component: Correlations,
      },
      {
        to: "/compose-bot",
        text: "Compose Bot",
        component: ComposeBot,
      },
      {
        to: "/team-output",
        text: "Team Output",
        component: AdminTeamOutput,
      },
      {
        to: "/logout",
        text: "Logout",
        component: () => {
          window.localStorage.clear();
          window.location.reload();
          return null;
        },
      },
    ];

    return (
      <>
        <div className="admin__nav">
          <ul className="admin__list">
            {links.map(link => (
              <li key={link.to} className="admin__list-item">
                <Link to={link.to} className="admin__list-item-link">
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <Switch>
          {links.map(link => (
            <Route key={link.to} path={link.to} component={link.component} />
          ))}
        </Switch>
      </>
    );
  }
}

const Main = ({ userInfo, isLoading, error, ...props }) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <>
        <Redirect from="/" to="/login" />
        <Route exact path="/login" component={AdminLogin} />
      </>
    );
  }

  if (userInfo) {
    return (
      <Switch>
        <Route path="/mobile-simulator" component={MobileSimulator} />
        <Route component={LoggedIn} />
      </Switch>
    );
  }

  // Need a redirect if not logged in, but for some reason here it causes
  // logged in users to go to /login !
  return (
    <>
      <Route exact path="/login" component={AdminLogin} />
    </>
  );
};

const reqOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + window.localStorage.jwt,
  },
};

const Admin = () => (
  <div className="admin">
    <Router>
      <>
        <Route path="/link/sign-up" component={LinkSignUp} />
        <AuthProvider
          authUrl={`${process.env.REACT_APP_API_HOST || "/"}admin/user`}
          reqOptions={reqOptions}
        >
          <AuthConsumer>{args => <Main {...args} />}</AuthConsumer>
        </AuthProvider>
      </>
    </Router>
  </div>
);

export default Admin;
