import React, { Component } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import "./bot_message.scss";

class BotMessage extends Component {
  constructor(props) {
    super(props);

    this.outer_ref = React.createRef();
    this.inner_ref = React.createRef();
  }

  apply_inline_height = () => {
    let inner_cbr = this.inner_ref.current.getBoundingClientRect();

    this.outer_ref.current.style.height = `${inner_cbr.height}px`;
  };

  remove_inline_height = () => {
    this.outer_ref.current.style.removeProperty("height");
  };

  apply_squash_class = () => {
    this.outer_ref.current.classList.add("state--force_squash");
  };

  remove_squash_class = () => {
    this.outer_ref.current.classList.remove("state--force_squash");
  };

  render() {
    return (
      <div
        className={cn(
          "bot__message",
          "state--force_squash",
          `${this.props.animation_name}--enter`,
          {
            "bot__message--bot": this.props.from_bot,
            "bot__message--user": !this.props.from_bot,
            "bot__message--is_next": this.props.is_next,
          },
        )}
        ref={this.outer_ref}
      >
        <div className="bot__message_inner" ref={this.inner_ref}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

BotMessage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  animation_name: PropTypes.string.isRequired,
  from_bot: PropTypes.bool,
  is_next: PropTypes.bool,
};

BotMessage.defaultProps = {
  from_bot: false,
  is_next: false,
};

export default BotMessage;
