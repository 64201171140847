import React, { Component } from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { grid, colors, borderRadius } from "util/constants.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const primaryButton = 0;

const getBackgroundColor = ({ isSelected, isGhosting }) => {
  if (isGhosting) {
    return colors.grey.light;
  }

  if (isSelected) {
    return colors.blue.light;
  }

  return colors.grey.light;
};

const getColor = ({ isSelected, isGhosting }) => {
  if (isGhosting) {
    return "darkgrey";
  }
  if (isSelected) {
    return colors.blue.deep;
  }
  return colors.black;
};

const Container = styled.div`
  background-color: ${props => getBackgroundColor(props)};
  color: ${props => getColor(props)};
  padding: ${grid}px;
  padding-bottom: 0px;
  margin-bottom: ${grid}px;
  border-radius: ${borderRadius}px;
  font-size: 14px;
  border: 1px solid ${colors.shadow};

  ${props =>
    props.isDragging
      ? `box-shadow: 2px 2px 1px ${colors.shadow};`
      : ""} ${props =>
    props.isGhosting
      ? "opacity: 0.8;"
      : ""}

  /* needed for SelectionCount */
  position: relative;

  /* avoid default outline which looks lame with the position: absolute; */
  &:focus {
    outline: none;
    border-color: ${colors.blue.deep};
  }
`;
/* stylelint-disable block-no-empty */
const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;
/* stylelint-enable */
const size = 30;

const SelectionCount = styled.div`
  right: -${grid}px;
  top: -${grid}px;
  color: ${colors.white};
  background: ${colors.blue.deep};
  border-radius: 50%;
  height: ${size}px;
  width: ${size}px;
  line-height: ${size}px;
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
`;

const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9,
};

export default class Message extends Component {
  onKeyDown = (event, provided, snapshot) => {
    if (provided.dragHandleProps) {
      provided.dragHandleProps.onKeyDown(event);
    }

    if (event.defaultPrevented) {
      return;
    }

    if (snapshot.isDragging) {
      return;
    }

    if (event.keyCode !== keyCodes.enter) {
      return;
    }

    // we are using the event for selection
    event.preventDefault();

    this.performAction(event);
  };

  // Using onClick as it will be correctly
  // preventing if there was a drag
  onClick = event => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== primaryButton) {
      return;
    }

    // marking the event as used
    event.preventDefault();

    this.performAction(event);
  };

  onTouchEnd = event => {
    if (event.defaultPrevented) {
      return;
    }

    // marking the event as used
    // we would also need to add some extra logic to prevent the click
    // if this element was an anchor
    event.preventDefault();
    this.props.toggleSelectionInGroup(this.props.message.id);
  };

  // Determines if the platform specific toggle selection in group key was used
  wasToggleInSelectionGroupKeyUsed = event => {
    const isUsingWindows = navigator.platform.indexOf("Win") >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  // Determines if the multiSelect key was used
  wasMultiSelectKeyUsed = event => event.shiftKey;

  performAction = event => {
    const {
      message,
      toggleSelection,
      toggleSelectionInGroup,
      multiSelectTo,
    } = this.props;

    if (this.wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(message.id);
      return;
    }

    if (this.wasMultiSelectKeyUsed(event)) {
      multiSelectTo(message.id);
      return;
    }

    toggleSelection(message.id);
  };

  onRemove = event => {
    event.stopPropagation();
    this.props.onRemove(this.props.message.id);
  };

  render() {
    const message = this.props.message;
    const index = this.props.index;
    const isSelected = this.props.isSelected;
    const selectionCount = this.props.selectionCount;
    const isGhosting = this.props.isGhosting;
    return (
      <Draggable draggableId={message.id} index={index}>
        {(provided, snapshot) => {
          const shouldShowSelection = snapshot.isDragging && selectionCount > 1;

          return (
            <Container
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={this.onClick}
              onTouchEnd={this.onTouchEnd}
              onKeyDown={event => this.onKeyDown(event, provided, snapshot)}
              isDragging={snapshot.isDragging}
              isSelected={isSelected}
              isGhosting={isGhosting}
            >
              <Content>
                <span style={{ color: "#444", marginRight: "8px" }}>
                  {index + 1}
                </span>
                <span style={{ marginRight: "auto" }}>{message.text}</span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "10px",
                    textAlign: "right",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        alignSelf: "flex-end",
                        background: "#d0d7f3",
                        border: "1px #d0d0d2 solid",
                        borderRadius: "12px",
                        marginBottom: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {message.section}
                    </span>
                    <span
                      style={{
                        // marginTop: "7px",
                        marginLeft: "7px",
                        color: "#999",
                      }}
                    >
                      {message.id}
                    </span>
                    {this.props.showRemoveIcon && (
                      <span
                        onClick={this.onRemove}
                        style={{
                          marginRight: "5px",
                          width: "15px",
                          height: "15px",
                          borderRadius: "50%",
                          alignSelf: "flex-start",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={faMinusCircle} color="#bfbfbf" />
                      </span>
                    )}
                  </span>
                  <span style={{ marginBottom: "3px", whiteSpace: "nowrap" }}>
                    {message.type}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      paddingTop: "5px",
                    }}
                  />{" "}
                </span>
              </Content>
              {shouldShowSelection ? (
                <SelectionCount>{selectionCount}</SelectionCount>
              ) : null}
            </Container>
          );
        }}
      </Draggable>
    );
  }
}
