import React from "react";
import api from "util/api.js";
import queryString from "query-string";

// TODO: This is just a stub - to be replaced by the real thing shortly
class LinkSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      password: "",
      error: null,
    };
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);

    this.setState({
      firstName: values.first_name,
      token: values.token,
      email: values.email,
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    api
      .post("/team/accept-invite", {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        token: this.state.token,
        email: this.state.email,
        password: this.state.password,
      })
      .then(result => {
        if (result.data.success === true) {
          window.localStorage.setItem("jwt", result.data.access_token);
          window.localStorage.setItem("user", JSON.stringify(result.data.user));
          window.location = "/";

          return;
        }

        this.setState({ error: result.data.error });
      });
  };

  render() {
    return (
      <div className="mt-3 ml-3">
        {this.state.error && (
          <div className="danger mb-2">{this.state.error}</div>
        )}
        <form onSubmit={this.handleSubmit}>
          <div>
            <label htmlFor="first_name">First Name</label>
            <input
              value={this.state.firstName}
              onChange={this.handleChange}
              name="firstName"
              type="text"
              autoComplete="name"
              required
            />
          </div>

          <div>
            <label htmlFor="last_name">Last Name</label>
            <input
              value={this.state.lastName}
              onChange={this.handleChange}
              name="lastName"
              type="text"
              autoComplete="name"
              required
            />
          </div>

          <div>
            <label htmlFor="password">Choose a Password</label>
            <input
              value={this.state.password}
              onChange={this.handleChange}
              name="password"
              type="password"
              autoComplete="current-password"
              required
            />
          </div>

          <div>
            <input type="submit" />
          </div>
        </form>
      </div>
    );
  }
}

export default LinkSignUp;
