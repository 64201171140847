import "scss/components/table.scss";
import "./submarkers.scss";

import _ from "lodash";
import cn from "classnames";
import { BOT_ID } from "util/constants.js";

import React from "react";
import api from "util/api.js";

class Submarkers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submarkers: [],
      selectedSubmarkerMessages: null,
      selectedSubmarker: null,
    };
  }

  async componentDidMount() {
    const { data: submarkers } = await api.get(
      `/admin/submarker-totals/?botId=` + BOT_ID,
    );
    this.setState({ submarkers });
  }

  selectSubmarker = async selectedSubmarker => {
    const { data: selectedSubmarkerMessages } = await api.get(
      `/admin/message-submarkers/${selectedSubmarker.id}`,
    );
    this.setState({
      selectedSubmarker,
      selectedSubmarkerMessages,
    });
  };

  Submarkers = () => {
    let currentMarker = null;

    return this.state.submarkers.map(sm => {
      let showHeader = false;

      // Show the marker name for the first submarker in each group.
      if (sm.marker_name !== currentMarker) {
        currentMarker = sm.marker_name;
        showHeader = true;
      }

      return (
        <React.Fragment key={sm.id}>
          {showHeader && (
            <thead className="table__head">
              <tr className="table__head-row">
                <td className="table__head-cell">{sm.marker_name}</td>
                <td className="table__head-cell">
                  {_(this.state.submarkers)
                    .filter({ marker_name: sm.marker_name })
                    .sumBy("num_messages")}
                </td>
                <td className="table__head-cell">
                  {_(this.state.submarkers)
                    .filter({ marker_name: sm.marker_name })
                    .sumBy("num_negative_values")}
                </td>
                <td className="table__head-cell">
                  {_(this.state.submarkers)
                    .filter({ marker_name: sm.marker_name })
                    .sumBy("num_neutral_values")}
                </td>
                <td className="table__head-cell">
                  {_(this.state.submarkers)
                    .filter({ marker_name: sm.marker_name })
                    .sumBy("num_positive_values")}
                </td>
                <td className="table__head-cell">
                  {(
                    ((_(this.state.submarkers)
                      .filter({ marker_name: sm.marker_name })
                      .sumBy("num_negative_values") +
                      _(this.state.submarkers)
                        .filter({ marker_name: sm.marker_name })
                        .sumBy("num_neutral_values") +
                      _(this.state.submarkers)
                        .filter({ marker_name: sm.marker_name })
                        .sumBy("num_positive_values")) /
                      _(this.state.submarkers)
                        .filter({ marker_name: sm.marker_name })
                        .sumBy("num_choices")) *
                    100
                  ).toFixed(0)}
                  %
                </td>
                <td className="table__head-cell">
                  {_(this.state.submarkers)
                    .filter({ marker_name: sm.marker_name })
                    .sumBy("min_value")
                    .toFixed(1)}
                </td>
                <td className="table__head-cell">
                  {_(this.state.submarkers)
                    .filter({ marker_name: sm.marker_name })
                    .sumBy("max_value")
                    .toFixed(1)}
                </td>
              </tr>
            </thead>
          )}
          <tbody key={sm.id}>
            <tr className="table__row" onClick={() => this.selectSubmarker(sm)}>
              <td className="table__cell">{sm.name}</td>
              <td className="table__cell">{sm.num_messages}</td>
              <td className="table__cell">{sm.num_negative_values}</td>
              <td className="table__cell">{sm.num_neutral_values}</td>
              <td className="table__cell">{sm.num_positive_values}</td>
              <td className="table__cell">
                {(
                  ((sm.num_positive_values +
                    sm.num_neutral_values +
                    sm.num_negative_values) /
                    sm.num_choices) *
                  100
                ).toFixed(0)}
                %
              </td>
              <td className="table__cell">{sm.min_value.toFixed(1)}</td>
              <td className="table__cell">{sm.max_value.toFixed(1)}</td>
            </tr>
          </tbody>
        </React.Fragment>
      );
    });
  };

  SubmarkerChoice = ({ choice }) => {
    // FIXME: Fix this in the back end.
    // See https://gist.github.com/euoia/2b64b4fc77e6c448d6f1a7dffea8874a.
    if (choice.choice_submarkers.length === 0) {
      return null;
    }

    return (
      <tr className="table__row">
        <td className="table__cell">{choice.choice}</td>
        <td
          className={cn("table__cell", "submarkers__submarker-value", {
            "submarkers__submarker-value-positive":
              choice.choice_submarkers[0].value > 0,
            "submarkers__submarker-value-negative":
              choice.choice_submarkers[0].value < 0,
          })}
        >
          {choice.choice_submarkers[0].value === 0
            ? "-"
            : choice.choice_submarkers[0].value.toFixed(1)}
        </td>
      </tr>
    );
  };

  SubmarkerMessages = ({ messages }) => {
    return (
      <div className="submarkers__submarker-messages">
        <div className="submarkers__submarker-name">
          {this.state.selectedSubmarker.name}
        </div>
        <div>
          <table className="table">
            {messages.map(message => (
              <>
                <thead className="table__head">
                  <tr className="table__head-row">
                    <th className="table__head-cell" colspan="2">
                      {message.text}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {message.choices.map(c => (
                    <this.SubmarkerChoice choice={c} />
                  ))}
                </tbody>
              </>
            ))}
          </table>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.submarkers.length === 0) {
      return <div>There are no submarkers!</div>;
    }

    return (
      <div className="submarkers__submarker">
        <table className="table">
          <thead className="table__head">
            <tr className="table__head-row">
              <th className="table__head-cell">Marker / Submarker</th>
              <th className="table__head-cell">Questions</th>
              <th className="table__head-cell">Negative Choices</th>
              <th className="table__head-cell">Neutral Choices</th>
              <th className="table__head-cell">Positive Choices</th>
              <th className="table__head-cell">Complete</th>
              <th className="table__head-cell">Min. Score</th>
              <th className="table__head-cell">Max. Score</th>
            </tr>
          </thead>
          <this.Submarkers />
        </table>
        {this.state.selectedSubmarkerMessages && (
          <this.SubmarkerMessages
            messages={this.state.selectedSubmarkerMessages}
          />
        )}
      </div>
    );
  }
}

export default Submarkers;
