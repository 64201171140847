import React from "react";
import Bot from "components/Bot/Bot.js";
import api from "util/api.js";
import { BOT_ID } from "util/constants.js";

class MobileSimulator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      previous_message: null,
      previous_answers: null,
    };
  }

  componentDidMount() {
    api.get("/user/message/?botId=" + BOT_ID).then(res => {
      this.setState({ ...res.data });
    });
  }

  render() {
    if (this.state.message === null) {
      return null;
    }

    return <Bot message={this.state.message} />;
  }
}

export default MobileSimulator;
