// @flow
import invariant from "tiny-invariant";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const withNewMessageIds = (column, messageIds) => ({
  id: column.id,
  title: column.title,
  messageIds,
});

const reorderSingleDrag = ({
  entities,
  selectedMessageIds,
  source,
  destination,
}) => {
  const sourceColumn = entities.columns[source.droppableId];
  // moving in the same list
  if (source.droppableId === destination.droppableId) {
    let reordered = sourceColumn.messageIds;

    if (sourceColumn.id === "bot") {
      reordered = reorder(
        sourceColumn.messageIds,
        source.index,
        destination.index,
      );
    } else {
      reordered = sourceColumn.messageIds.sort((a, b) => a - b);
    }

    const updated = {
      ...entities,
      columns: {
        ...entities.columns,
        [sourceColumn.id]: withNewMessageIds(sourceColumn, reordered),
      },
    };

    return {
      entities: updated,
      selectedMessageIds,
    };
  }

  // moving to a new list
  const home = entities.columns[source.droppableId];
  const foreign = entities.columns[destination.droppableId];

  // the id of the message to be moved
  const messageId = home.messageIds[source.index];

  // remove from home column
  const newHomeMessageIds = [...home.messageIds];
  newHomeMessageIds.splice(source.index, 1);

  // add to foreign column
  const newForeignMessageIds = [...foreign.messageIds];
  newForeignMessageIds.splice(destination.index, 0, messageId);

  if (sourceColumn.id === "bot") {
    newForeignMessageIds.sort((a, b) => a - b);
  }

  const updated = {
    ...entities,
    columns: {
      ...entities.columns,
      [home.id]: withNewMessageIds(home, newHomeMessageIds),
      [foreign.id]: withNewMessageIds(foreign, newForeignMessageIds),
    },
  };

  return {
    entities: updated,
    selectedMessageIds,
  };
};

export const getHomeColumn = (entities, messageId) => {
  const columnId = entities.columnOrder.find(id => {
    const column = entities.columns[id];
    return column.messageIds.includes(messageId);
  });

  invariant(columnId, "Could not find column for message");

  return entities.columns[columnId];
};

const reorderMultiDrag = ({
  entities,
  selectedMessageIds,
  source,
  destination,
}) => {
  const sourceColumn = entities.columns[source.droppableId];

  const start = entities.columns[source.droppableId];
  const dragged = start.messageIds[source.index];

  const insertAtIndex = (() => {
    const destinationIndexOffset = selectedMessageIds.reduce(
      (previous, current) => {
        if (current === dragged) {
          return previous;
        }

        const final = entities.columns[destination.droppableId];
        const column = getHomeColumn(entities, current);

        if (column !== final) {
          return previous;
        }

        const index = column.messageIds.indexOf(current);

        if (index >= destination.index) {
          return previous;
        }

        // the selected item is before the destination index
        // we need to account for this when inserting into the new location
        return previous + 1;
      },
      0,
    );

    const result = destination.index - destinationIndexOffset;
    return result;
  })();

  // doing the ordering now as we are required to look up columns
  // and know original ordering
  const orderedSelectedMessageIds = [...selectedMessageIds];
  orderedSelectedMessageIds.sort(
    (a, b) => a - b,

    // This is a more complicated way of sorting which makes sure the
    // item being dragged is at the top of the list

    // (a, b) => {
    //   // moving the dragged item to the top of the list
    //   if (a === dragged) {
    //     return -1;
    //   }
    //   if (b === dragged) {
    //     return 1;
    //   }

    //   // sorting by their natural indexes
    //   const columnForA = getHomeColumn(entities, a);
    //   const indexOfA = columnForA.messageIds.indexOf(a);
    //   const columnForB = getHomeColumn(entities, b);
    //   const indexOfB = columnForB.messageIds.indexOf(b);

    //   if (indexOfA !== indexOfB) {
    //     return indexOfA - indexOfB;
    //   }

    //   // sorting by their order in the selectedMessageIds list
    //   return -1;
    // },
  );

  // we need to remove all of the selected messages from their columns
  const withRemovedMessages = entities.columnOrder.reduce(
    (previous, columnId) => {
      const column = entities.columns[columnId];

      // remove the id's of the items that are selected
      const remainingMessageIds = column.messageIds.filter(
        id => !selectedMessageIds.includes(id),
      );

      previous[column.id] = withNewMessageIds(column, remainingMessageIds);
      return previous;
    },
    entities.columns,
  );

  const final = withRemovedMessages[destination.droppableId];
  const withInserted = (() => {
    const base = [...final.messageIds];
    base.splice(insertAtIndex, 0, ...orderedSelectedMessageIds);

    if (sourceColumn.id === "bot") {
      base.sort((a, b) => a - b);
    }

    return base;
  })();

  // insert all selected messages into final column
  const withAddedMessages = {
    ...withRemovedMessages,
    [final.id]: withNewMessageIds(final, withInserted),
  };

  const updated = {
    ...entities,
    columns: withAddedMessages,
  };

  return {
    entities: updated,
    selectedMessageIds: orderedSelectedMessageIds,
  };
};

export const mutliDragAwareReorder = args => {
  if (args.selectedMessageIds.length > 1) {
    return reorderMultiDrag(args);
  }
  return reorderSingleDrag(args);
};

export const multiSelectTo = (entities, selectedMessageIds, newMessageId) => {
  // Nothing already selected
  if (!selectedMessageIds.length) {
    return [newMessageId];
  }

  const columnOfNew = getHomeColumn(entities, newMessageId);
  const indexOfNew = columnOfNew.messageIds.indexOf(newMessageId);

  const lastSelected = selectedMessageIds[selectedMessageIds.length - 1];
  const columnOfLast = getHomeColumn(entities, lastSelected);
  const indexOfLast = columnOfLast.messageIds.indexOf(lastSelected);

  // multi selecting to another column
  // select everything up to the index of the current item
  if (columnOfNew !== columnOfLast) {
    return columnOfNew.messageIds.slice(0, indexOfNew + 1);
  }

  // multi selecting in the same column
  // need to select everything between the last index and the current index inclusive

  // nothing to do here
  if (indexOfNew === indexOfLast) {
    return null;
  }

  const isSelectingForwards = indexOfNew > indexOfLast;
  const start = isSelectingForwards ? indexOfLast : indexOfNew;
  const end = isSelectingForwards ? indexOfNew : indexOfLast;

  const inBetween = columnOfNew.messageIds.slice(start, end + 1);

  // everything inbetween needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected

  const toAdd = inBetween.filter(messageId => {
    // if already selected: then no need to select it again
    if (selectedMessageIds.includes(messageId)) {
      return false;
    }
    return true;
  });

  const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
  const combined = [...selectedMessageIds, ...sorted];

  return combined;
};
