import "scss/components/table.scss";
import "./users.scss";

import React from "react";
import { BOT_ID } from "util/constants.js";
import api from "util/api.js";

const truncate = require("friendly-truncate");
const hdate = require("human-date");
const duration = require("human-duration");

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user: null,
      scores: [],
      filters: {
        marker: null,
      },
      markers: [],
    };
  }

  componentDidMount() {
    api.get("/admin/users").then(({ data: users }) => {
      this.setState({ users });
    });
    api
      .get("/user/normalized-scores/?botId=" + BOT_ID)
      .then(({ data: scores }) => {
        scores = scores.filter(score => score.length !== 0);

        this.setState({ scores });
      });
    api.get("/admin/markers").then(({ data: markers }) => {
      const protuModelMarkers = markers.filter(marker => marker.model_id === 1);

      const filters = {
        marker: protuModelMarkers.length > 0 ? protuModelMarkers[0].name : null,
      };
      this.setState({ markers: protuModelMarkers, filters });
    });
  }

  showUser = id => {
    api.get(`/admin/user/${id}`).then(({ data: user }) => {
      this.setState({ user });
    });
  };

  getMarkers = scores => {
    if (scores.length === 0) {
      return [];
    }

    let unique = {};
    let distinct = [];

    for (let i in scores[0]) {
      if (typeof unique[scores[0][i].marker_id] == "undefined") {
        distinct.push(scores[0][i].marker_name);
      }
      unique[scores[0][i].marker_id] = 0;
    }

    return distinct;
  };

  getTotalAvgScore(userId, marker) {
    // FIXME: there ought to be a more succinct way of doing this...
    let totalScore = 0;
    let num_submarkers = 0;

    for (let i = 0; i < this.state.scores.length; i++) {
      for (let j = 0; j < this.state.scores[i].length; j++) {
        if (
          this.state.scores[i][j].user_id === userId &&
          this.state.scores[i][j].marker_name === marker
        ) {
          num_submarkers += 1;
          totalScore += this.state.scores[i][j].normalized_score;
        }
      }
    }

    if (totalScore !== 0) {
      return totalScore / num_submarkers;
    }

    return 0;
  }

  getUserAverage(marker) {
    let averageScore = 0;

    // TODO: should be able to do this more efficiently since we are calculating
    // the total scores for each user anyway...
    for (let uidx = 0; uidx < this.state.users.length; uidx++) {
      averageScore +=
        this.getTotalAvgScore(this.state.users[uidx].id, marker) /
        this.state.users.length;
    }

    return averageScore.toFixed(2);
  }

  setFilter(attr, value) {
    //localStorage.setItem(attr, value);
    this.setState({
      filters: {
        ...this.state.filters,
        [attr]: value,
      },
    });
  }

  UserDetail = ({ user }) => {
    return (
      <>
        <div className="users__user_detail_name">{user.name}</div>
        <table className="table">
          <thead className="table__head-row">
            <tr className="table__row">
              <th className="table__head-cell">#</th>
              <th className="table__head-cell">Question</th>
              <th className="table__head-cell">Response</th>
              <th className="table__head-cell">Time taken</th>
            </tr>
          </thead>

          <tbody className="table__body">
            {user.user_answers.length > 0 ? (
              user.user_answers.map(ua => (
                <tr className="table__row users__user_detail_row">
                  <td className="table__cell">
                    <b>{ua.message && ua.message.order}</b>
                  </td>
                  <td className="table__cell">
                    {ua.message && truncate.truncateEnd(ua.message.text, 80)}
                  </td>
                  <td className="table__cell">
                    {ua.text && <b>{ua.text}</b>}
                    {ua.choice && <b>{ua.choice.choice}</b>}
                  </td>
                  <td className="table__cell">
                    {duration.fmt(ua.time_taken).toString()}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>This user has not answered any questions.</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };

  UserAverage = ({ userAvg, userScore }) => {
    let scoreJsx = null;

    if (
      isNaN(userAvg) ||
      isNaN(userScore) ||
      parseFloat(userAvg) === 0.0 ||
      parseFloat(userScore) === 0.0
    ) {
      scoreJsx = <div>n/a</div>;
    } else if (userScore > userAvg) {
      scoreJsx = (
        <div className="users__score-higher-than-average">
          {(((userScore - userAvg) / userAvg) * 100).toFixed(2)}%
        </div>
      );
    } else {
      scoreJsx = (
        <div className="users__score-lower-than-average">
          {(((userScore - userAvg) / userAvg) * 100).toFixed(2)}%
        </div>
      );
    }

    return scoreJsx;
  };

  render() {
    if (this.state.users === null) {
      return null;
    }

    const markers = this.state.markers ? this.state.markers : [];

    return (
      <div className="users">
        <table className="table">
          <thead className="table__head">
            <tr className="table__head-row">
              {[
                "User",
                "First Answer",
                "Last Answer",
                "Last Question",
                "Progress",
                "Skipped / Don't Knows",
                "Completed",
                <select
                  name="markers"
                  value={
                    this.state.filters.marker
                      ? this.state.filters.marker
                      : markers.length > 0
                      ? markers[0]
                      : ""
                  }
                  className="admin-input"
                  onChange={e => this.setFilter("marker", e.target.value)}
                >
                  >
                  {markers.map((m, i) => (
                    <option key={i}>{m.name}</option>
                  ))}
                </select>,
                "User Average",
              ].map(header => (
                <th key={header} className="table__head-cell">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table__body">
            {this.state.users.map(u => (
              <tr
                key={u.id}
                onClick={() => this.showUser(u.id)}
                className="table__row"
              >
                <td className="table__cell">{u.email}</td>
                <td className="table__cell">
                  {hdate.relativeTime(u.first_answer_time)}
                </td>
                <td className="table__cell">
                  {hdate.relativeTime(u.last_answer_time)}
                </td>
                <td className="table__cell" style={{ color: "blue" }}>
                  {u.current_message_order}
                </td>
                <td className="table__cell">
                  {Math.round(
                    (u.current_message_order / u.total_messages) * 100,
                  )}
                  %
                </td>
                <td className="table__cell">{u.message_skipped_count}</td>
                <td className="table__cell">
                  {u.total_messages -
                    u.current_message_order -
                    u.message_skipped_count}
                </td>
                <td className="table__cell">
                  {this.state.filters.marker &&
                    this.getTotalAvgScore(
                      parseInt(u.id),
                      this.state.filters.marker,
                    ).toFixed(2)}
                </td>
                <td className="table__cell">
                  <this.UserAverage
                    userAvg={this.getUserAverage(this.state.filters.marker)}
                    userScore={this.getTotalAvgScore(
                      parseInt(u.id),
                      this.state.filters.marker,
                    ).toFixed(2)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="users__admin-console">
          {this.state.user && <this.UserDetail user={this.state.user} />}
        </div>
      </div>
    );
  }
}

export default Users;
