import "./simulator.scss";

import React from "react";
import cn from "classnames";

import api from "util/api.js";
import RadarChart from "components/RadarChart/RadarChart.js";
import Button from "components/Button/Button.js";
import Bot from "components/Bot/Bot.js";
import ReactHover from "react-hover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { BOT_ID } from "util/constants.js";

class Simulator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: null,
      scores: {},
      previousScores: {},
      message: null,
      previous_message: null,
      previous_answers: null,
    };
  }

  componentDidMount = async () => {
    api.get("/user/message/?botId=" + BOT_ID).then(res => {
      this.setState({
        ...res.data,
      });
    });

    const { data: markers } = await api.get("/admin/markers");

    // FIXME: hardcoding to use the protu markers at index 0 for now
    const protuModelMarkers = markers.filter(marker => marker.model_id === 1);

    this.setState({ markers: protuModelMarkers });

    const { data: scores } = await api.get("/user/scores", {
      params: { bot_id: BOT_ID },
    });
    this.setState({
      scores,
      previousScores: { ...this.state.scores },
    });
  };

  back = () => {
    api.post("/admin/simulator/go-back", { bot_id: BOT_ID }).then(res => {
      this.setState({ ...res.data });
    });
  };

  messageChanged = async (message, scores) => {
    if (message.id !== this.state.message.id) {
      this.setState({
        message,
      });

      const { data: scores } = await api.get("/user/scores", {
        params: { bot_id: BOT_ID },
      });
      this.setState({
        scores,
        previousScores: { ...this.state.scores },
      });
    }
  };

  getSubmarkerScore = submarkerId => {
    if (this.state.scores[submarkerId] === undefined) {
      return 0;
    }

    return this.state.scores[submarkerId].normalized_score;
  };

  getMarkerScore = marker => {
    const val = marker.submarkers.reduce(
      (acc, sm) => this.getSubmarkerScore(sm.id) + acc,
      0,
    );

    // Mean.
    return val / marker.submarkers.length;
  };

  formatScore = score => {
    if (score === undefined) {
      score = 0;
    }

    if (score === 0) {
      return "-";
    }

    return score.toFixed(2);
  };

  scoreWentUp = submarkerId => {
    if (
      this.state.previousScores[submarkerId] === undefined ||
      this.state.scores[submarkerId] === undefined
    ) {
      return false;
    }

    return (
      this.state.scores[submarkerId].normalized_score >
      this.state.previousScores[submarkerId].normalized_score
    );
  };

  scoreWentDown = submarkerId => {
    if (
      this.state.previousScores[submarkerId] === undefined ||
      this.state.scores[submarkerId] === undefined
    ) {
      return false;
    }

    return (
      this.state.scores[submarkerId].normalized_score <
      this.state.previousScores[submarkerId].normalized_score
    );
  };

  markerRadarChartData = () => {
    // Convert the database format to the format required for D3.
    // TODO: Should probably do this in the RadarChart component.
    const chartData = [
      this.state.markers.map(marker => {
        return { axis: marker.name, value: this.getMarkerScore(marker) * 100 };
      }),
    ];
    return chartData;
  };

  AdminConsole = () => {
    const showSubmarkers = true;

    const hoverOptions = {
      followCursor: false,
      shiftX: 5,
      shiftY: 5,
    };

    return (
      <div className="simulator__admin_console">
        <div className="simulator__previous_answer">
          <span className="simulator__previous_message_text">
            <i>
              {this.state.previous_message && this.state.previous_message.text}
            </i>
          </span>
          {this.state.previous_answers &&
            this.state.previous_answers.map((a, i) => (
              <span key={a.id} id={a.id} className="simulator__previous_choice">
                {a.choice !== null && (
                  <span>
                    {i + 1}
                    &#41;&nbsp; {a.choice.choice}
                  </span>
                )}
                {a.text !== null && (
                  <span>
                    {i + 1}
                    &#41;&nbsp; {a.text}
                  </span>
                )}
              </span>
            ))}
        </div>

        <div className="simulator__markers">
          <div className="simulator__marker_group">
            {this.state.markers && (
              <RadarChart
                data={this.markerRadarChartData()}
                width={360}
                height={160}
                margin_top={50}
                margin_bottom={50}
                max_value={100}
                label_factor={1.34}
                enable_round_strokes={false}
              />
            )}
          </div>
          <div
            style={{
              width: `100%`,
              display: `flex`,
              flexFlow: `row wrap`,
              maxWidth: `700px`,
              marginTop: `30px`,
              marginLeft: `25px`,
            }}
          >
            {this.state.markers &&
              this.state.markers.map(m => (
                <div className="simulator__marker_group" key={m.id}>
                  <div className="simulator__marker">
                    <ReactHover options={hoverOptions}>
                      <ReactHover.Trigger type="trigger">
                        <div className="simulator__marker_name">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            color="#bfbfbf"
                          />{" "}
                          {m.name}
                        </div>
                      </ReactHover.Trigger>
                      <ReactHover.Hover type="hover">
                        <div
                          style={{
                            width: `300px`,
                            backgroundColor: `#fefefe`,
                            padding: `20px`,
                            borderRadius: `8px`,
                            border: `1px #dedede solid`,
                          }}
                        >
                          {m.explanations}
                        </div>
                      </ReactHover.Hover>
                    </ReactHover>
                    <div
                      className={cn("simulatore__submarker_score", {
                        simulator__score_positive: this.getMarkerScore(m) > 0,
                        simulator__score_negative: this.getMarkerScore(m) < 0,
                      })}
                    >
                      <b>{(this.getMarkerScore(m) * 100).toFixed(2)}%</b>
                    </div>
                  </div>

                  {showSubmarkers &&
                    m.submarkers.map(sm => (
                      <div className="simulator__submarker" key={sm.id}>
                        <ReactHover options={hoverOptions}>
                          <ReactHover.Trigger type="trigger">
                            <div className="simulator__submarker_name">
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                color="#bfbfbf"
                              />{" "}
                              {sm.name}
                            </div>
                          </ReactHover.Trigger>
                          <ReactHover.Hover type="hover">
                            <div
                              style={{
                                width: `300px`,
                                backgroundColor: `#fefefe`,
                                padding: `20px`,
                                borderRadius: `8px`,
                                border: `1px #dedede solid`,
                              }}
                            >
                              {sm.explanations}
                            </div>
                          </ReactHover.Hover>
                        </ReactHover>
                        <div
                          className={cn("simulator__submarker_score", {
                            simulator__score_went_up: this.scoreWentUp(sm.id),
                            simulator__score_went_down: this.scoreWentDown(
                              sm.id,
                            ),
                          })}
                        >
                          {this.formatScore(this.getSubmarkerScore(sm.id))}
                        </div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="simulator">
        {this.state.message && (
          <>
            <div className="simulator__input">
              <div className="simulator__controls">
                Question #{this.state.message.order}
                <Button
                  text="Back"
                  onClick={this.back}
                  disabled={
                    this.state.message.order === 1 ||
                    this.state.message.is_last_message
                  }
                />
              </div>

              <div className="simulator__bot_container">
                <Bot
                  message={this.state.message}
                  messageChanged={this.messageChanged}
                />
              </div>
            </div>

            <this.AdminConsole />
          </>
        )}
      </div>
    );
  }
}

export default Simulator;
