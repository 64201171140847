import React, { Component } from "react";
import _ from "lodash";
import "./bot.scss";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import api from "util/api.js";
import BotMessage from "components/BotMessage/BotMessage.js";
import BotMessageText from "components/BotMessageText/BotMessageText.js";
import BotMessageExplain from "components/BotMessageExplain/BotMessageExplain.js";
import BotSelectedChoice from "components/BotSelectedChoice/BotSelectedChoice.js";
import BotButton from "components/BotButton/BotButton.js";
import BotUserForm from "components/BotUserForm/BotUserForm.js";
import BotUserChoices from "components/BotUserChoices/BotUserChoices.js";
import BotUserChoice from "components/BotUserChoice/BotUserChoice.js";
import ProtuCharacter from "components/ProtuCharacter/ProtuCharacter.js";
//import backSVG from "images/back.svg";
import removeSVG from "images/remove.svg";
import { BOT_ID } from "util/constants.js";

class Bot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_loading: true,
      is_submitting: false,
      questions: [],
      question: {},
      next_questions: [],
      next_question: {},
      answer: null,
      selected_choices: [],
      is_next_visible: false,
      is_input_visible: false,
      search_term: null,
      search_suggestions: undefined,
      progress: 0,
    };

    this.animation_duration = 800;
    /*
     * Each choice/suggestion button animates in very slightly after the
     * previous one, creating a very subtle staircase effect.
     *
     * This difference is set to 50ms in the SCSS and to get the CSSTransition
     * timeouts correct we need it here also.
     */
    this.animation_sequencing = 50;

    this.bot_ref = React.createRef();
    this.messages_scroll_ref = React.createRef();
    this.bot_message_refs = {};
    this.user_message_refs = {};
    this.user_selected_choice_refs = {};
    this.user_fake_button_refs = {};
    this.user_choice_refs = {};
    this.messageChanged = this.props.messageChanged || (() => {});
    this.finish_bot = this.props.finish_bot || (() => {});
  }

  componentDidMount() {
    this.setState({
      questions: [this.props.message],
      question: this.props.message,
      progress: this.props.progress,
    });
  }

  componentWillReceiveProps = async props => {
    if (props.message.id !== this.state.question.id) {
      // The parent component changed the message.
      await this.setState({
        questions: [props.message],
        question: props.message,
        next_questions: [],
        next_question: {},
        previous_question: props.message,
      });
    }
  };

  bot_getBoundingClientRect = element_ref => {
    let bot_cbr = this.bot_ref.current.getBoundingClientRect(),
      element_cbr = element_ref.current.getBoundingClientRect();

    return {
      top: element_cbr.top - bot_cbr.top,
      left: element_cbr.left - bot_cbr.left,
      width: element_cbr.width,
      height: element_cbr.height,
    };
  };

  add_bot_message_ref = component => {
    if (component) {
      this.bot_message_refs[component.props.id] = component;
    }
  };

  add_user_message_ref = component => {
    if (component) {
      this.user_message_refs[component.props.id] = component;
    }
  };

  force_scroll = () => {
    this.messages_scroll_ref.current.scrollTop = this.messages_scroll_ref.current.scrollHeight;
  };

  load_next_question = () => {
    this.setState({
      questions: [this.state.next_question],
      question: this.state.next_question,
      next_questions: [],
      next_question: {},
    });

    this.messageChanged(this.state.next_question);
  };

  submit_conversational = async () => {
    const { data } = await api.post("/user/answer-message", { bot_id: BOT_ID });

    if (data.is_complete) {
      this.finish_bot();
    }

    this.setState({
      questions: [...this.state.questions, data.message],
      question: data.message,
      previous_question: this.state.question,
      progress: data.progress,
    });
  };

  /**
   * Submit a free text response.
   */
  submit_answer = async () => {
    this.setState({
      is_submitting: true,
    });

    const { data } = await api.post("/user/answer-message", {
      bot_id: BOT_ID,
      choice_texts: [this.state.answer],
      skip_message: "No",
    });

    if (data.is_complete) {
      this.finish_bot();
    }

    this.setState({
      is_loading: true,
      is_submitting: false,
      questions: [],
      question: {},
      next_questions: [data.message],
      next_question: data.message,
      previous_question: this.state.question,
      answer: null,
      is_next_visible: false,
      is_input_visible: false,
      progress: data.progress,
    });
  };

  submit_choices = async () => {
    this.setState({
      is_submitting: true,
    });

    // TODO: It's a bit gross how we're checking the type of the ID here,
    // perhaps fix it so when selected_choices is inserted into we push
    // type="suggestion".
    const { data } = await api.post("/user/answer-message", {
      bot_id: BOT_ID,
      choice_ids: _(this.state.selected_choices)
        .filter(choice => choice.type === "choice")
        .map("id")
        .value(),
      choice_texts: _(this.state.selected_choices)
        .filter(
          choice =>
            choice.type === "suggestion" || typeof choice.id === "string",
        )
        .map("choice")
        .value(),
      skip_message: "No",
    });

    if (data.is_complete) {
      this.finish_bot();
    }

    await this.setState({
      is_loading: true,
      is_submitting: false,
      questions: [],
      question: {},
      next_questions: [data.message],
      next_question: data.message,
      previous_question: this.state.question,
      selected_choices: [],
      is_next_visible: false,
      is_input_visible: false,
      search_term: null,
      search_suggestions: undefined,
      progress: data.progress,
    });
  };

  apply_answer = new_answer => {
    this.setState({
      answer: new_answer,
      is_input_visible: false,
    });
  };

  apply_selected_choice = new_selected_choice => {
    this.setState({
      selected_choices: [
        ...this.state.selected_choices,
        {
          ...new_selected_choice,
          is_fake_exit_enabled:
            this.state.question.type !== "search" &&
            this.state.question.has_dont_know === "Yes",
        },
      ],
      is_input_visible:
        this.state.question.has_dont_know === "Yes" ||
        !this.will_maximum_choices_be_reached(),
      search_term: null,
      search_suggestions: undefined,
    });
  };

  remove_selected_choice = deselected_choice_id => {
    let filtered_selected_choices = this.state.selected_choices.filter(
      selected_choice => selected_choice.id !== deselected_choice_id,
    );

    this.setState({
      selected_choices: filtered_selected_choices,
      is_input_visible: true,
    });
  };

  apply_selected_suggestion = search_term => {
    if (
      _.find(this.state.selected_choices, { choice: search_term }) === undefined
    ) {
      let exact_match_suggestion = this.state.search_suggestions.filter(
        suggestion =>
          suggestion.choice.toLowerCase() === search_term.toLowerCase() &&
          typeof suggestion.id !== "string",
      );

      let new_selected_choice =
        exact_match_suggestion.length > 0
          ? exact_match_suggestion[0]
          : { id: search_term, choice: search_term };

      this.apply_selected_choice(new_selected_choice);
    }
  };

  apply_suggestions = async search_term => {
    if (!search_term) {
      this.setState({
        search_term: null,
        search_suggestions: undefined,
      });
    } else {
      const { data: suggestions } = await api.get("/user/suggestions", {
        params: {
          suggestion_list_id: this.state.question.suggestion_list_id,
          text: this.state.text,
        },
      });

      let dynamic_suggestion =
        suggestions.filter(
          suggestion =>
            suggestion.choice.toLowerCase() === search_term.toLowerCase(),
        ).length === 0
          ? [{ id: search_term, choice: search_term }]
          : [];

      let filtered_suggestions = suggestions.filter(suggestion =>
        suggestion.choice.toLowerCase().includes(search_term.toLowerCase()),
      );

      this.setState({
        search_term: search_term,
        search_suggestions: [...dynamic_suggestion, ...filtered_suggestions],
      });
    }
  };

  apply_explanation = () => {
    this.setState({
      questions: [
        ...this.state.questions,
        {
          id: `${this.state.question.id} explanation`,
          text_formatted: this.state.question.explanation,
        },
      ],
    });
  };

  handle_user_form_input_submit = input_value => {
    if (this.state.question.type === "free text") {
      this.apply_answer(input_value);
    } else if (this.state.question.type === "search") {
      this.apply_selected_suggestion(input_value);
    }
  };

  handle_user_form_input_change = input_value => {
    this.apply_suggestions(input_value);
  };

  handle_user_choice_select = selected_choice_id => {
    let choices_to_filter = ["multiple choice", "single choice"].includes(
      this.state.question.type,
    )
      ? this.state.question.choices
      : ["search"].includes(this.state.question.type)
      ? this.state.search_suggestions
      : undefined;

    if (choices_to_filter) {
      let selected_choice = choices_to_filter.filter(
        choice => choice.id === selected_choice_id,
      );

      this.apply_selected_choice(selected_choice[0]);
    }
  };

  handle_user_choice_deselect = deselected_choice_id => {
    this.remove_selected_choice(deselected_choice_id);
  };

  handle_user_request_explanation = () => {
    this.apply_explanation();
  };

  store_selected_choice_ref = (new_choice_id, message_ref) => {
    this.user_selected_choice_refs[new_choice_id] = message_ref;
  };

  delete_selected_choice_ref = choice_id => {
    delete this.user_selected_choice_refs[choice_id];
  };

  store_choice_ref = (choice_id, button_ref) => {
    this.user_choice_refs[choice_id] = button_ref;
  };

  delete_choice_ref = choice_id => {
    delete this.user_choice_refs[choice_id];
  };

  store_fake_button_ref = (new_choice_id, fake_button_ref) => {
    this.user_fake_button_refs[new_choice_id] = fake_button_ref;
  };

  delete_fake_button_ref = choice_id => {
    delete this.user_fake_button_refs[choice_id];
  };

  apply_fake_button_choice_location = fake_button_choice_id => {
    let choice_button_cbr = this.bot_getBoundingClientRect(
      this.user_choice_refs[fake_button_choice_id],
    );

    this.user_fake_button_refs[fake_button_choice_id].current.style.width = `${
      choice_button_cbr.width
    }px`;

    this.user_fake_button_refs[fake_button_choice_id].current.style.height = `${
      choice_button_cbr.height
    }px`;

    let selected_choice_cbr = this.bot_getBoundingClientRect(
      this.user_selected_choice_refs[fake_button_choice_id],
    );

    this.user_fake_button_refs[fake_button_choice_id].current.style.top = `${
      selected_choice_cbr.top
    }px`;

    this.user_fake_button_refs[fake_button_choice_id].current.style.left = `${
      selected_choice_cbr.left
    }px`;

    let difference = {
      vertical: choice_button_cbr.top - selected_choice_cbr.top,
      horizontal: choice_button_cbr.left - selected_choice_cbr.left,
    };

    this.user_fake_button_refs[
      fake_button_choice_id
    ].current.style.transform = `translate(
        ${difference.horizontal}px,
        ${difference.vertical}px
      )`;
  };

  apply_fake_button_message_location = fake_button_choice_id => {
    let selected_choice_cbr = this.bot_getBoundingClientRect(
      this.user_selected_choice_refs[fake_button_choice_id],
    );

    this.user_fake_button_refs[fake_button_choice_id].current.style.width = `${
      selected_choice_cbr.width
    }px`;

    this.user_fake_button_refs[fake_button_choice_id].current.style.height = `${
      selected_choice_cbr.height
    }px`;

    this.user_fake_button_refs[
      fake_button_choice_id
    ].current.style.transform = `translate(0, 0)`;
  };

  update_fake_button_message_locations = () => {
    for (let selected_choice_id in this.user_fake_button_refs) {
      let selected_choice_cbr = this.bot_getBoundingClientRect(
        this.user_selected_choice_refs[selected_choice_id],
      );

      this.user_fake_button_refs[selected_choice_id].current.style.top = `${
        selected_choice_cbr.top
      }px`;

      this.user_fake_button_refs[selected_choice_id].current.style.left = `${
        selected_choice_cbr.left
      }px`;
    }
  };

  enable_selected_choice_fake_exit = entered_choice_id => {
    this.setState({
      selected_choices: this.state.selected_choices.map(selected_choice => {
        if (selected_choice.id === entered_choice_id) {
          return {
            ...selected_choice,
            is_fake_exit_enabled: true,
          };
        } else {
          return selected_choice;
        }
      }),
    });
  };

  disable_selected_choice_fake_exit = exited_choice_id => {
    this.setState({
      selected_choices: this.state.selected_choices.map(selected_choice => {
        if (selected_choice.id === exited_choice_id) {
          return {
            ...selected_choice,
            is_fake_exit_enabled: false,
          };
        }

        return selected_choice;
      }),
    });
  };

  is_maximum_choices_reached = () =>
    this.state.selected_choices.length ===
      this.state.question.maximum_choices ||
    (this.state.selected_choices.length === 1 &&
      !this.state.question.maximum_choices);

  will_maximum_choices_be_reached = () =>
    this.state.selected_choices.length + 1 ===
      this.state.question.maximum_choices ||
    (this.state.selected_choices.length === 0 &&
      !this.state.question.maximum_choices);

  get_custom_next_button_text = () => {
    return this.state.question.custom_dont_know_message !== null
      ? this.state.question.custom_dont_know_message
      : "Skip";
  };

  get_next_button_text = () => {
    switch (this.state.question.type) {
      case "multiple choice":
      case "single choice":
      case "search":
        return this.state.selected_choices.length === 0
          ? this.get_custom_next_button_text()
          : this.state.selected_choices.length ===
              this.state.question.maximum_choices ||
            !this.state.question.maximum_choices
          ? "Next"
          : "That's all!";

      case "free text":
        return this.state.answer === null
          ? this.get_custom_next_button_text()
          : "Next";

      default:
        return "Next";
    }
  };

  render() {
    const choices_to_display = ["multiple choice", "single choice"].includes(
      this.state.question.type,
    )
      ? this.state.question.choices
      : ["search"].includes(this.state.question.type)
      ? this.state.search_suggestions
      : undefined;

    return (
      <div className="bot" ref={this.bot_ref}>
        <div
          className="bot__progress"
          style={{ width: `${this.state.progress * 100}%` }}
        />

        <div className="bot__header">
          <ProtuCharacter
            is_loading={this.state.is_loading}
            is_submitting={this.state.is_submitting}
          />

          {/*
            // TODO Enable back button once the functionality is implemented.
            <BotButton
              colour="grey_mid"
              icon={backSVG}
              icon_position="left"
              text="Back"
            />
          */}
        </div>

        <div className="bot__messages" ref={this.messages_scroll_ref}>
          <div className="bot__messages_inner">
            <TransitionGroup component={null}>
              {this.state.questions.map((question, index) => (
                <CSSTransition
                  key={question.id}
                  timeout={this.animation_duration}
                  classNames={{
                    appear: "",
                    appearActive: "",
                    enter: "bubble--enter",
                    enterActive: "bubble--enter_active",
                    enterDone: "bubble--enter_done",
                    exit: "to_left--exit",
                    exitActive: "to_left--exit_active",
                    exitDone: "",
                  }}
                  onEnter={() => {
			  console.log("on enter");
                    this.bot_message_refs[question.id].apply_inline_height();

                    this.force_scroll();
                  }}
                  onEntering={() =>
                    this.bot_message_refs[question.id].remove_squash_class()
                  }
                  onEntered={() => {
                    this.bot_message_refs[question.id].remove_inline_height();

                    if (question.type === "conversational") {
                      this.submit_conversational();
                    } else {
                      this.setState({
                        is_loading: false,
                        is_next_visible: true,
                        is_input_visible: true,
                      });
                    }
                  }}
                  onExit={() =>
                    this.bot_message_refs[question.id].apply_inline_height()
                  }
                  onExiting={() =>
                    this.bot_message_refs[question.id].apply_squash_class()
                  }
                  onExited={() => {
                    this.bot_message_refs[question.id].remove_inline_height();

                    if (question.id === this.state.previous_question.id) {
                      this.load_next_question();
                    }
                  }}
                >
                  <BotMessage
                    id={question.id}
                    ref={this.add_bot_message_ref}
                    from_bot
                    animation_name="bubble"
                  >
                    <BotMessageText text={question.text_formatted}>
                      {question.explanation && this.state.is_input_visible && (
                        <BotMessageExplain
                          show_explanation={
                            this.handle_user_request_explanation
                          }
                        />
                      )}
                    </BotMessageText>
                  </BotMessage>
                </CSSTransition>
              ))}
            </TransitionGroup>

            <TransitionGroup component={null}>
              {this.state.answer && (
                <CSSTransition
                  timeout={this.animation_duration}
                  classNames={{
                    appear: "",
                    appearActive: "",
                    enter: "bubble--enter",
                    enterActive: "bubble--enter_active",
                    enterDone: "bubble--enter_done",
                    exit: "to_right--exit",
                    exitActive: "to_right--exit_active",
                    exitDone: "",
                  }}
                  onEnter={() =>
                    this.user_message_refs["answer"].apply_inline_height()
                  }
                  onEntering={() => {
                    this.user_message_refs["answer"].remove_squash_class();

                    this.force_scroll();
                  }}
                  onEntered={() => {
                    this.user_message_refs["answer"].remove_inline_height();

                    if (this.state.question.has_dont_know === "No") {
                      this.submit_answer();
                    }
                  }}
                  onExit={() =>
                    this.user_message_refs["answer"].apply_inline_height()
                  }
                  onExiting={() =>
                    this.user_message_refs["answer"].apply_squash_class()
                  }
                  onExited={() => {
                    this.user_message_refs["answer"].remove_inline_height();
                  }}
                >
                  <BotMessage
                    id="answer"
                    ref={this.add_user_message_ref}
                    animation_name="bubble"
                  >
                    <BotMessageText text={this.state.answer} />
                  </BotMessage>
                </CSSTransition>
              )}
            </TransitionGroup>

            <TransitionGroup component={null}>
              {this.state.selected_choices.map(selected_choice => (
                <CSSTransition
                  key={selected_choice.id}
                  timeout={{
                    enter: this.animation_duration / 2,
                    exit:
                      selected_choice.is_fake_exit_enabled &&
                      this.state.is_input_visible
                        ? this.animation_duration / 2
                        : this.animation_duration,
                  }}
                  classNames={{
                    appear: "",
                    appearActive: "",
                    enter: "post_fake--enter",
                    enterActive: "post_fake--enter_active",
                    enterDone: "post_fake--enter_done",
                    exit:
                      selected_choice.is_fake_exit_enabled &&
                      this.state.is_input_visible
                        ? ""
                        : "to_right--exit",
                    exitActive:
                      selected_choice.is_fake_exit_enabled &&
                      this.state.is_input_visible
                        ? "post_fake--exit_active"
                        : "to_right--exit_active",
                    exitDone:
                      selected_choice.is_fake_exit_enabled &&
                      this.state.is_input_visible
                        ? ""
                        : "to_right--exit_done",
                  }}
                  onEnter={() => {
                    this.user_message_refs[
                      selected_choice.id
                    ].apply_inline_height();

                    this.force_scroll();
                  }}
                  onEntering={() => {
                    this.user_message_refs[
                      selected_choice.id
                    ].remove_squash_class();
                  }}
                  onEntered={() => {
                    this.user_message_refs[
                      selected_choice.id
                    ].remove_inline_height();

                    if (
                      this.is_maximum_choices_reached() &&
                      this.state.question.has_dont_know === "No"
                    ) {
                      this.submit_choices();
                    }
                  }}
                  onExit={() =>
                    this.user_message_refs[
                      selected_choice.id
                    ].apply_inline_height()
                  }
                  onExiting={() =>
                    this.user_message_refs[
                      selected_choice.id
                    ].apply_squash_class()
                  }
                  onExited={() =>
                    this.user_message_refs[
                      selected_choice.id
                    ].remove_inline_height()
                  }
                >
                  <BotMessage
                    id={selected_choice.id}
                    ref={this.add_user_message_ref}
                    animation_name="post_fake"
                  >
                    <BotSelectedChoice
                      choice={selected_choice}
                      on_mount_emit_ref={this.store_selected_choice_ref}
                      is_deselectable={
                        this.state.question.has_dont_know === "Yes"
                      }
                      deselect_choice={this.handle_user_choice_deselect}
                      on_unmount_delete_ref={this.delete_selected_choice_ref}
                    />
                  </BotMessage>
                </CSSTransition>
              ))}
            </TransitionGroup>

            <CSSTransition
              timeout={this.animation_duration}
              in={
                this.state.question.has_dont_know === "Yes" &&
                this.state.is_next_visible
              }
              classNames={{
                appear: "",
                appearActive: "",
                enter: "from_right--enter",
                enterActive: "from_right--enter_active",
                enterDone: "from_right--enter_done",
                exit: "",
                exitActive: "to_right--exit_active",
                exitDone: "to_right--exit_done",
              }}
              onEnter={() => {
                this.user_message_refs["next"].apply_inline_height();

                this.force_scroll();
              }}
              onEntering={() =>
                this.user_message_refs["next"].remove_squash_class()
              }
              onEntered={() =>
                this.user_message_refs["next"].remove_inline_height()
              }
              onExit={() =>
                this.user_message_refs["next"].apply_inline_height()
              }
              onExiting={() =>
                this.user_message_refs["next"].apply_squash_class()
              }
              onExited={() =>
                this.user_message_refs["next"].remove_inline_height()
              }
            >
              <BotMessage
                id="next"
                ref={this.add_user_message_ref}
                is_next={true}
                animation_name="from_right"
              >
                <BotButton
                  colour={
                    this.state.answer !== null ||
                    this.state.selected_choices.length > 0
                      ? "navy"
                      : "grey_mid"
                  }
                  variant="hollow"
                  text={this.get_next_button_text()}
                  on_click={() => {
                    this.setState({
                      is_next_visible: false,
                      is_input_visible: false,
                    });

                    switch (this.state.question.type) {
                      case "single choice":
                      case "multiple choice":
                      case "search":
                        this.submit_choices();
                        break;

                      case "free text":
                        this.submit_answer();
                        break;

                      default:
                        break;
                    }
                  }}
                />
              </BotMessage>
            </CSSTransition>
          </div>
        </div>

        <TransitionGroup component={null}>
          {this.state.selected_choices.map(selected_choice => (
            <CSSTransition
              key={selected_choice.id}
              timeout={{
                enter: this.animation_duration / 2,
                exit: selected_choice.is_fake_exit_enabled
                  ? this.animation_duration / 2
                  : 0,
              }}
              classNames={{
                appear: "",
                appearActive: "",
                enter: "fake--enter",
                enterActive: "fake--enter_active",
                enterDone: "fake--enter_done",
                exit:
                  selected_choice.is_fake_exit_enabled &&
                  this.state.is_input_visible
                    ? ""
                    : "null--exit",
                exitActive: "fake--exit_active",
                exitDone: "",
              }}
              onEnter={() => {
                this.apply_fake_button_choice_location(selected_choice.id);
              }}
              onEntering={() => {
                this.apply_fake_button_message_location(selected_choice.id);
              }}
              onEntered={() => {
                this.update_fake_button_message_locations();
              }}
              onExit={() => {
                selected_choice.is_fake_exit_enabled &&
                  this.state.is_input_visible &&
                  this.apply_fake_button_message_location(selected_choice.id);
              }}
              onExiting={() => {
                selected_choice.is_fake_exit_enabled &&
                  this.state.is_input_visible &&
                  this.apply_fake_button_choice_location(selected_choice.id);
              }}
            >
              <BotButton
                colour="purple_dark"
                variant="solid"
                text={selected_choice.choice}
                icon={
                  this.state.question.has_dont_know === "Yes"
                    ? removeSVG
                    : undefined
                }
                icon_position="right"
                fake={true}
                on_mount_emit_ref={this.store_fake_button_ref}
                on_unmount_delete_ref={this.delete_fake_button_ref}
                choice_id={selected_choice.id}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>

        <div className="bot__user_input">
          {["single choice", "multiple choice", "search"].includes(
            this.state.question.type,
          ) && (
            <BotUserChoices>
              {this.state.question.maximum_choices > 1 && (
                <CSSTransition
                  timeout={{
                    enter: this.animation_duration - this.animation_sequencing,
                    exit: this.animation_duration + this.animation_sequencing,
                  }}
                  in={!!choices_to_display && this.state.is_input_visible}
                  enter={this.state.question.type !== "search"}
                  exit={this.state.question.type !== "search"}
                  unmountOnExit={true}
                  classNames={{
                    appear: "",
                    appearActive: "",
                    enter: "from_below--enter",
                    enterActive: "from_below--enter_active",
                    enterDone: "from_below--enter_done",
                    exit: "",
                    exitActive: "to_below--exit_active",
                    exitDone: "to_below--exit_done",
                  }}
                >
                  <h6 className="bot__user_input_choices_title from_below--enter">
                    Pick up to {this.state.question.maximum_choices}
                  </h6>
                </CSSTransition>
              )}

              <TransitionGroup
                className="bot__user_input_choices_list"
                exit={this.state.question.type !== "search"}
              >
                {this.state.is_input_visible &&
                  choices_to_display &&
                  choices_to_display.map((choice, index) => (
                    <CSSTransition
                      key={choice.id}
                      timeout={{
                        enter:
                          this.animation_duration +
                          this.animation_sequencing * index,
                        exit: this.animation_duration / 2,
                      }}
                      classNames={{
                        appear: "",
                        appearActive: "",
                        enter:
                          this.state.question.type === "search"
                            ? ""
                            : "from_below--enter",
                        enterActive:
                          this.state.question.type === "search"
                            ? ""
                            : "from_below--enter_active",
                        enterDone:
                          this.state.question.type === "search"
                            ? ""
                            : "from_below--enter_done",
                        exit: "",
                        exitActive: "to_below--exit_active",
                        exitDone: "to_below--exit_done",
                      }}
                      onEnter={() => {
                        if (
                          this.state.is_input_visible &&
                          !!this.state.selected_choices.find(
                            selected_choice => selected_choice.id === choice.id,
                          )
                        ) {
                          this.enable_selected_choice_fake_exit(choice.id);
                        }
                      }}
                      onExited={() => {
                        if (
                          this.state.is_input_visible &&
                          !!this.state.selected_choices.find(
                            selected_choice => selected_choice.id === choice.id,
                          )
                        ) {
                          this.disable_selected_choice_fake_exit(choice.id);
                        }
                      }}
                    >
                      <BotUserChoice
                        choice={choice}
                        search_term={this.state.search_term}
                        on_mount_emit_ref={this.store_choice_ref}
                        select_choice={this.handle_user_choice_select}
                        on_unmount_delete_ref={this.delete_choice_ref}
                        is_selected={
                          !!this.state.selected_choices.find(
                            selected_choice => selected_choice.id === choice.id,
                          )
                        }
                        is_disabled={
                          !!this.state.selected_choices.find(
                            selected_choice => selected_choice.id === choice.id,
                          ) || this.is_maximum_choices_reached()
                        }
                      />
                    </CSSTransition>
                  ))}
              </TransitionGroup>
            </BotUserChoices>
          )}

          {["free text", "search"].includes(this.state.question.type) && (
            <BotUserForm
              search_term={this.state.search_term}
              is_input_visible={this.state.is_input_visible}
              on_change={
                this.state.question.type === "search"
                  ? this.handle_user_form_input_change
                  : undefined
              }
              on_submit={this.handle_user_form_input_submit}
              is_disabled={this.is_maximum_choices_reached()}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Bot;
