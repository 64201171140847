import "./edit_message_modal.scss";
import React from "react";

class EditMessageModal extends React.Component {
  componentWillMount() {
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.body.style.overflow = null;
  }

  render() {
    return (
      <div className="edit_message_modal__wrapper">
        <div className="edit_message_modal__content">{this.props.children}</div>
      </div>
    );
  }
}

export default EditMessageModal;
