import "./radar_chart.scss";

import React from "react";
import ReactFauxDOM from "react-faux-dom";
import PropTypes from "prop-types";
const d3 = require("d3");

/**
 * Function to draw the path of a rounded rectangle.
 * https://stackoverflow.com/questions/12115691/svg-d3-js-rounded-corner-on-one-corner-of-a-rectangle
 * x: x-coordinate
 * y: y-coordinate
 * w: width
 * h: height
 * r: corner radius
 * tl: top_left rounded?
 * tr: top_right rounded?
 * bl: bottom_left rounded?
 * br: bottom_right rounded?
 */
function rounded_rect(x, y, w, h, r, tl, tr, bl, br) {
  var retval;
  retval = "M" + (x + r) + "," + y;
  retval += "h" + (w - 2 * r);
  if (tr) {
    retval += "a" + r + "," + r + " 0 0 1 " + r + "," + r;
  } else {
    retval += "h" + r;
    retval += "v" + r;
  }
  retval += "v" + (h - 2 * r);
  if (br) {
    retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + r;
  } else {
    retval += "v" + r;
    retval += "h" + -r;
  }
  retval += "h" + (2 * r - w);
  if (bl) {
    retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + -r;
  } else {
    retval += "h" + -r;
    retval += "v" + -r;
  }
  retval += "v" + (2 * r - h);
  if (tl) {
    retval += "a" + r + "," + r + " 0 0 1 " + r + "," + -r;
  } else {
    retval += "v" + -r;
    retval += "h" + r;
  }
  retval += "z";
  return retval;
}

class RadarChart extends React.Component {
  render() {
    const el = ReactFauxDOM.createElement("div");

    // If the supplied max_value is smaller than the actual one, replace by the
    // max in the data
    var max_value = Math.max(
      this.props.max_value,
      d3.max(this.props.data, i => {
        return d3.max(
          i.map(o => {
            return o.value;
          }),
        );
      }),
    );

    var total = this.props.data[0].length, // The number of different axes
      radius = Math.min(this.props.width / 2, this.props.height / 2), // Radius of the outermost circle
      Format = d3.format("%"), // Percentage formatting
      angleSlice = (Math.PI * 2) / total; // The width in radians of each "slice"

    // Scale for the radius
    var rScale = d3
      .scaleLinear()
      .range([0, radius])
      .domain([0, max_value]);

    /////////////////////////////////////////////////////////
    //////////// Create the container SVG and g /////////////
    /////////////////////////////////////////////////////////

    // Initiate the radar chart SVG
    var svg = d3
      .select(el)
      .append("svg")
      .attr(
        "width",
        this.props.width + this.props.margin_left + this.props.margin_right,
      )
      .attr(
        "height",
        this.props.height + this.props.margin_top + this.props.margin_bottom,
      );

    // Append a g element
    var g = svg
      .append("g")
      .attr(
        "transform",
        "translate(" +
          (this.props.width / 2 + this.props.margin_left) +
          "," +
          (this.props.height / 2 + this.props.margin_top) +
          ")",
      );

    /////////////////////////////////////////////////////////
    ////////// Glow filter for some extra pizzazz ///////////
    /////////////////////////////////////////////////////////

    // Filter for the outside glow
    //var filter = g
    //.append('defs')
    //.append('filter')
    //.attr('id', 'glow'),
    //feGaussianBlur = filter
    //.append('feGaussianBlur')
    //.attr('stdDeviation', '2.5')
    //.attr('result', 'coloredBlur'),
    //feMerge = filter.append('feMerge');
    //feMergeNode_1 = feMerge.append('feMergeNode').attr('in', 'coloredBlur'),
    //feMergeNode_2 = feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    /////////////////////////////////////////////////////////
    /////////////// Draw the Circular grid //////////////////
    /////////////////////////////////////////////////////////

    // Wrapper for the grid & axes
    var axis_grid = g.append("g");

    var defs = svg.append("svg:defs");

    defs
      .append("svg:pattern")
      .attr("id", "company-logo")
      .attr("width", "1")
      .attr("height", "1")
      .attr("patternUnits", "objectBoundingBox")
      .append("svg:image")
      .attr("xlink:href", this.props.logo)
      .attr("width", "77")
      .attr("height", "77")
      .attr("x", "12px")
      .attr("y", "17px");

    // Draw circles for each "level".
    axis_grid
      .selectAll(".levels")
      .data(d3.range(1, this.props.levels + 1).reverse())
      .enter()
      .append("circle")
      .attr("class", "radar_chart__levels")
      .style("stroke", "#ccc")
      .style("stroke-width", "2px")
      .attr("fill", "rgba(0,0,0,0)")
      .attr("r", d => {
        return (radius / this.props.levels) * d;
      });

    // Text indicating at what % each level is.
    if (this.props.enable_level_labels === true) {
      axis_grid
        .selectAll(".axisLabel")
        .data(d3.range(1, this.props.levels + 1).reverse())
        .enter()
        .append("text")
        .attr("x", 4)
        .attr("y", d => {
          return (-d * radius) / this.props.levels;
        })
        .attr("dy", "0.4em")
        .style("font-size", "10px")
        .attr("fill", "#737373")
        .text((d, i) => {
          return Format((max_value * d) / this.props.levels);
        });
    }

    /////////////////////////////////////////////////////////
    //////////////////// Draw the axes //////////////////////
    /////////////////////////////////////////////////////////

    // Points on the edge.
    var axis = axis_grid
      .selectAll(".axis")
      .data((d, i) => this.props.data[0])
      .enter()
      .append("g");

    if (this.props.enable_axis_grid) {
      // Create straight lines radiating outward from the center.
      axis
        .append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", (d, i) => {
          return (
            rScale(max_value * 1.1) * Math.cos(angleSlice * i - Math.PI / 2)
          );
        })
        .attr("y2", i => {
          return (
            rScale(max_value * 1.1) * Math.sin(angleSlice * i - Math.PI / 2)
          );
        })
        .style("stroke", "white")
        .style("stroke-width", "2px");
    }

    // Append labels at each axis edge point.
    axis
      .append("text")
      .text(d => {
        return d.axis;
      })
      .attr("class", "radar_chart__legend")
      .attr("text-anchor", "middle")
      .attr("x", (d, i) => {
        //const angle = angleSlice * i * (180 / Math.PI);
        const x =
          rScale(max_value * this.props.label_factor) *
          Math.cos(angleSlice * i - Math.PI / 2) *
          1.05;

        return x;
      })
      .attr("y", (d, i) => {
        const y =
          rScale(max_value * this.props.label_factor) *
          Math.sin(angleSlice * i - Math.PI / 2);

        return y;
      })
      .on("click", (d, i) => this.props.handle_label_click(this, d, i));

    // Append axis values underneath labels.
    if (this.props.enable_axis_values) {
      var label_value_group = axis_grid
        .selectAll(".axis")
        .data((d, i) => this.props.data[1])
        .enter()
        .append("g")
        .attr("class", "radar_chart__legend_group")
        .attr("transform", (d, i) => {
          const x =
            rScale(max_value * this.props.label_factor) *
            Math.cos(angleSlice * i - Math.PI / 2) *
            1.05;

          const y =
            rScale(max_value * this.props.label_factor) *
              Math.sin(angleSlice * i - Math.PI / 2) +
            20;

          return `translate(${x}, ${y})`;
        });

      label_value_group
        .append("path")
        .attr("class", "radar_chart__legend_border")
        .attr("width", "100px")
        .attr("height", "30px")
        .attr("d", d => {
          if (d.showDataCircle) {
            return rounded_rect(-35, -5, 70, 30, 15, 1, 1, 1, 1);
          }
        });

      label_value_group
        .append("text")
        .attr("class", "radar_chart__legend_values")
        .attr("y", 15)
        .attr("x", -15)
        .text((d, i) =>
          d.showDataCircle ? `${Math.round(d.value * 100)}%` : "",
        );
    }

    // Append circles at axis point.
    if (this.props.enable_axis_circles) {
      axis
        .append("circle")
        .attr("class", "radar_chart__axis_circles")
        .attr("r", () => {
          return "4px";
        })
        .attr("cx", (d, i) => {
          return (
            rScale(max_value) * Math.cos(angleSlice * i - Math.PI / 2) * 1.1
          );
        })
        .attr("cy", (d, i) => {
          return (
            rScale(max_value) * Math.sin(angleSlice * i - Math.PI / 2) * 1.1
          );
        })
        .style("fill", this.props.label_dot_color)
        .on("click", d => this.props.handle_label_click(this, d));
    }

    /////////////////////////////////////////////////////////
    ////////////////// Draw the chart data  /////////////////
    /////////////////////////////////////////////////////////

    // Create the data points.
    var dataPoints = d3
      .lineRadial()
      .radius(d => {
        return rScale(d.value);
      })
      .angle((d, i) => {
        return i * angleSlice;
      });

    if (this.props.enable_round_strokes) {
      dataPoints.curve(d3.curveCardinalClosed.tension(0.5));
    }

    // Create a wrapper for the data.
    var dataWrapper = g
      .selectAll(".radarWrapper")
      .data(this.props.data)
      .enter()
      .append("g")
      .attr("class", "radarWrapper");

    // Create the data fill areas.
    dataWrapper
      .append("path")
      .attr("class", "radar_chart__data_fill")
      .attr("d", d => {
        return dataPoints(d);
      })
      .on("mouseover", function(d, i) {
        // Dim all blobs
        d3.selectAll(".radarArea")
          .transition()
          .duration(200)
          .style("fill-opacity", 0.1);
        // Bring back the hovered over blob
        d3.select(this)
          .transition()
          .duration(200)
          .style("fill-opacity", 0.7);
      })
      .on("mouseout", () => {
        // Bring back all blobs
        d3.selectAll(".radarArea")
          .transition()
          .duration(200)
          .style("fill-opacity", this.props.opacity_area);
      });

    // Outline the data area.
    dataWrapper
      .append("path")
      .attr("class", "radar_chart__data_outline")
      .attr("d", d => {
        return dataPoints(d);
      })
      .style("stroke-width", this.props.stroke_width + "px")
      .style("stroke", (d, i) => {
        return this.props.color(i).stroke;
      });

    // Add circles at each data point on the chart.
    dataWrapper
      .selectAll(".radarCircle")
      .data((d, dataset_idx) => {
        // Add the index of the dataset to the data, so it can be used in the
        // color function.
        return d.map(data => ({ ...data, dataset_idx }));
      })
      .enter()
      .append("circle")
      .attr("class", "radar_chart__data_point")
      .attr("r", this.props.data_point_radius)
      .attr("cx", (d, i) => {
        return rScale(d.value) * Math.cos(angleSlice * i - Math.PI / 2);
      })
      .attr("cy", (d, i) => {
        return rScale(d.value) * Math.sin(angleSlice * i - Math.PI / 2);
      })
      .style("stroke", d => {
        return this.props.color(d.dataset_idx).stroke;
      })
      .style("display", d => {
        if (d.showDataCircle !== undefined && d.showDataCircle === false) {
          return "none";
        }
      });

    return el.toReact();
  }
}

RadarChart.propTypes = {
  data: PropTypes.array.isRequired, // The chart data.
  width: PropTypes.number, // Width of the circle.
  height: PropTypes.number, // Height of the circle.
  margin_top: PropTypes.number, // The margins of the circle.
  margin_right: PropTypes.number,
  margin_bottom: PropTypes.number,
  margin_left: PropTypes.number,
  levels: PropTypes.number, // How many level circles should be drawn.
  max_value: PropTypes.number.isRequired, // What is the value that the biggest circle will represent
  label_factor: PropTypes.number, // How much farther than the radius of the outer circle should the labels be placed
  wrap_width: PropTypes.number, // The number of pixels after which a label needs to be given a new line
  opacity_area: PropTypes.number, // The opacity of the area of the blob TODO: improve docs
  data_point_radius: PropTypes.number, // The size of the circles at each data point.
  handle_label_click: () => {}, // Handler when a label is clicked.
  label_dot_color: PropTypes.string, // Color of the dot shown at each label point.
  logo: PropTypes.string, // Href for logo to be shown in center.
  enable_round_strokes: PropTypes.bool, // Whether to smooth the edges of the data lines.
  enable_level_labels: PropTypes.bool, // Whether to show % markers on each level ring.
  enable_axis_grid: PropTypes.bool, // Whether to show lines from the center to each marker label.
  enable_axis_circles: PropTypes.bool, // Whether to draw circles at each axis point.
  enable_axis_values: PropTypes.bool, // Whether to draw the data values on the axis
  color: PropTypes.func, // D3 Color function.
};

RadarChart.defaultProps = {
  width: 400, // Width of the circle
  height: 400, // Height of the circle
  margin_top: 0, // The margins of the SVG
  margin_right: 0,
  margin_bottom: 0,
  margin_left: 0,
  levels: 4,
  max_value: 1,
  label_factor: 1,
  wrap_width: 60,
  opacity_area: 0.35,
  data_point_radius: 8,
  enable_round_strokes: true,
  enable_level_labels: false,
  enable_axis_grid: false,
  enable_axis_circles: false,
  enable_axis_values: false,
  handle_label_click: () => {},
  label_dot_color: "#7283EC",
  logo: "",
  color: d3.scaleOrdinal().range([
    {
      stroke: "#6B75FA",
      fill: "rgba(0, 0, 0, 0)",
    },
    {
      stroke: "#6DBFF3",
      fill: "rgba(0, 0, 0, 0)",
    },
  ]),
};

export default RadarChart;
