import React, { Component } from "react";
import BotButton from "components/BotButton/BotButton.js";
import PropTypes from "prop-types";

class BotUserChoice extends Component {
  select_choice = () => {
    this.props.select_choice(this.props.choice.id);
  };

  render() {
    return (
      <BotButton
        colour="purple_dark"
        variant={this.props.is_selected ? "solid" : "hollow"}
        text={this.props.choice.choice}
        text_highlight={this.props.search_term}
        on_mount_emit_ref={this.props.on_mount_emit_ref}
        on_click={this.select_choice}
        on_unmount_delete_ref={this.props.on_unmount_delete_ref}
        choice_id={this.props.choice.id}
        is_disabled={this.props.is_disabled}
      />
    );
  }
}

BotUserChoice.propTypes = {
  choice: PropTypes.object.isRequired,
  search_term: PropTypes.string,
  on_mount_emit_ref: PropTypes.func.isRequired,
  select_choice: PropTypes.func.isRequired,
  on_unmount_delete_ref: PropTypes.func.isRequired,
  is_selected: PropTypes.bool.isRequired,
  is_disabled: PropTypes.bool.isRequired,
};

BotUserChoice.defaultProps = {
  search_term: undefined,
};

export default BotUserChoice;
