import React from "react";
import cn from "classnames";

import "./button.scss";
import PropTypes from "prop-types";

class Button extends React.Component {
  render() {
    return (
      <div
        className={cn("button", {
          "button--width_narrow": this.props.width === "narrow",
          "button--width_medium": this.props.width === "medium",
          "button--width_wide": this.props.width === "wide",
          "button--width_fill": this.props.width === "fill",
          "button--inline": this.props.inline,
        })}
      >
        {this.props.label && (
          <div className="button__label-container">
            <label className="button__label" htmlFor={this.props.name}>
              {this.props.label}
            </label>
            )}
          </div>
        )}
        <button
          className={cn("button__field", {
            "button--variant_save": this.props.variant === "save",
            "button--variant_cancel": this.props.variant === "cancel",
            "button--variant_delete": this.props.variant === "delete",
            "button--variant_action": this.props.variant === "action",
          })}
          type={this.props.type}
          placeholder={this.props.placeholder}
          readOnly={this.props.readonly}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          onClick={this.props.onClick}
        >
          {this.props.text}
        </button>
      </div>
    );
  }
}

Button.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(["text", "number"]).isRequired,
  value: PropTypes.string,
  width: PropTypes.oneOf(["", "narrow", "medium", "wide", "fill"]),
  variant: PropTypes.oneOf(["delete", "cancel", "save", "action"]),
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  type: "text",
  width: "",
  readonly: false,
  diabled: false,
  inline: false,
};

export default Button;
