import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST || "",
  headers: {
    Authorization: "Bearer " + window.localStorage.jwt,
  },
});

export default api;
