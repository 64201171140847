import React from "react";
import _ from "lodash";
import "./team_output.scss";
import api from "util/api.js";
import RadarChart from "components/RadarChart/RadarChart.js";
import Select from "components/Select/Select.js";
import handle_input_change from "util/handle_input_change.js";
import currentUser from "util/currentUser.js";
import BaseTeamOutput from "components/TeamOutput/BaseTeamOutput.js";
import { INDIVIDUAL_TRAITS_MODEL_ID } from "util/constants.js";

class TeamOutput extends BaseTeamOutput {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      current_company_id: "",
      current_team_id: "",
      current_user_id: "",
      markers: [],
      submarkers: [],
      submarker_scores: [],
    };

    this.handle_input_change = handle_input_change.bind(this);
  }

  componentDidMount = async () => {
    const [{ data: companies }, { data: markers }] = await Promise.all([
      api.get("/admin/companies"),
      api.get(`/models/${INDIVIDUAL_TRAITS_MODEL_ID}/markers`),
    ]);

    const initial_company_id = currentUser.company_id || companies[0].id;

    // Index the markers and submarkers on their ID.
    // TODO: Might be sensible if this was done server-side.
    this.setState({
      companies,
      current_company_id: initial_company_id,
      markers: _.keyBy(markers, "id"),
      submarkers: _(markers)
        .flatMap("submarkers")
        .keyBy("id")
        .value(),
    });

    const { data: submarker_scores } = await api.get(
      `/admin/company/${initial_company_id}/submarker-scores`,
    );

    this.setState({
      submarker_scores: submarker_scores,
    });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.current_company_id !== prevState.current_company_id) {
      this.setState({
        submarker_scores: null,
        current_user_id: "",
        current_team_id: "",
      });

      const { data: submarker_scores } = await api.get(
        `/admin/company/${this.state.current_company_id}/submarker-scores`,
      );

      this.setState({
        submarker_scores: submarker_scores,
      });
    }

    // If the previously selected user is no longer in the new team, reset the
    // user selection.
    if (
      this.state.current_team_id !== prevState.current_team_id &&
      this.get_current_team() &&
      _.find(this.get_team_users(), { id: this.state.current_user_id }) ===
        undefined
    ) {
      this.setState({ current_user_id: "" });
    }
  };

  render() {
    if (this.state.companies.length === 0) {
      return null;
    }

    return (
      <div className="team_output">
        <div className="site_width">
          <div className="team_output__column">
            <h1 className="team_output__title">{this.get_group_name()}</h1>

            <label className="team_output__label">Choose company</label>

            <Select
              name="current_company_id"
              value={this.current_company_id}
              className="team_output__select"
              onChange={this.handle_input_change}
              options={this.state.companies.map(company => ({
                value: company.id,
                text: company.name,
              }))}
            />

            <label className="team_output__label">Choose team</label>

            <Select
              name="current_team_id"
              value={this.current_team_id}
              className="team_output__select"
              onChange={this.handle_input_change}
              options={[
                {
                  value: "",
                  text: "None",
                },
                ...this.get_current_company().teams.map(team => ({
                  value: team.id,
                  text: team.name,
                })),
              ]}
            />

            <label className="team_output__label">Choose member</label>

            <Select
              name="current_user_id"
              value={this.state.current_user_id}
              className="team_output__select"
              onChange={this.handle_input_change}
              options={[
                {
                  value: "",
                  text: "None",
                },
                ...this.get_member_choices().map(user => ({
                  value: user.id,
                  text: `${user.first_name} ${user.last_name}`,
                })),
              ]}
            />

            {this.state.submarker_scores === null ? (
              <div className="team_output__loading">Loading data...</div>
            ) : (
              <div className="team_output__results">
                <h2 className="team_output__results_title">
                  {`${this.get_current_focus_name()}'s `}
                  Performance Indicators
                </h2>

                <ul className="team_output__indicators">
                  {this.get_current_focus_avg_submarker_scores()
                    .reverse()
                    .map((submarker_score, submarker_idx) => (
                      <li
                        key={submarker_score.submarker_id}
                        className="team_output__indicator"
                      >
                        <div className="team_output__indicator_marker_name">
                          {submarker_idx + 1} {submarker_score.marker.name}
                        </div>

                        <span className="team_output__indicator_name">
                          {submarker_score.name}
                        </span>

                        <div className="team_output__indicator_tooltip">
                          <h3 className="team_output__indicator_member_name">
                            {this.get_current_focus_name()}
                          </h3>

                          <span className="team_output__indicator_reiterate_name">
                            {submarker_score.name}
                          </span>

                          <span className="team_output__indicator_score">
                            {Math.floor(submarker_score.score * 100)}%
                          </span>

                          <p className="team_output__indicator_description">
                            {submarker_score.explanations}
                          </p>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>

          {this.state.submarker_scores && (
            <div className="team_output__column">
              <div className="team_output__radar_chart">
                <RadarChart
                  data={this.get_radar_chart_data()}
                  margin_top={100}
                  margin_bottom={100}
                  margin_left={100}
                  margin_right={100}
                  max_value={1}
                  label_factor={1.18}
                  enable_axis_values={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TeamOutput;
