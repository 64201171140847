import React, { Component } from "react";
import PropTypes from "prop-types";
import "./bot_message_text.scss";

class BotMessageText extends Component {
  render() {
    return (
      <div className="bot__message_inner_content">
        <p
          className="bot__message_text"
          dangerouslySetInnerHTML={{ __html: this.props.text }}
        />

        {this.props.children}
      </div>
    );
  }
}

BotMessageText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default BotMessageText;
