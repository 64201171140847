import "./compose_bot.scss";

import React, { Component } from "react";
import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./Column";
import { mutliDragAwareReorder, multiSelectTo as multiSelect } from "./utils";
import api from "util/api.js";
import { BOT_ID } from "util/constants.js";
import Button from "components/Button/Button.js";

const Container = styled.div`
  display: flex;
  user-select: none;
  height: 90%;
`;

const getMessages = (entities, columnId) => {
  const retVal = entities.columns[columnId].messageIds.map(
    messageId => entities.messages[messageId],
  );

  return retVal;
};

export default class ComposeBot extends Component {
  state = {
    entities: {
      columnOrder: ["messageList", "bot"],
      columns: {
        messageList: {
          id: "messageList",
          title: "Messages",
          messageIds: [],
        },

        bot: {
          id: "bot",
          title: "",
          messageIds: [],
        },
      },
      messages: {},
    },
    selectedMessageIds: [],
    draggingMessageId: null,
  };

  componentDidMount() {
    this.retrieveMessagesFromServer();

    window.addEventListener("click", this.onWindowClick);
    window.addEventListener("keydown", this.onWindowKeyDown);
    window.addEventListener("touchend", this.onWindowTouchEnd);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onWindowClick);
    window.removeEventListener("keydown", this.onWindowKeyDown);
    window.removeEventListener("touchend", this.onWindowTouchEnd);
  }

  async saveBot() {
    const botMessagesObj = {
      bot_id: BOT_ID,
      message_ids: this.state.entities.columns["bot"].messageIds,

      // (Start ordering from 1)
      ordering: this.state.entities.columns["bot"].messageIds.map(
        (el, idx) => idx + 1,
      ),
    };

    await api.post(`/admin/bot-messages`, botMessagesObj).catch(error => {
      alert(error.response.data.error);
      console.dir(error);
    });
  }

  async retrieveMessagesFromServer() {
    const { data: messageListServer } = await api.get(`/admin/messages`);
    const { data: botMessagesServer } = await api.get(
      `/admin/bot-messages/?botId=` + BOT_ID,
    );
    const { data: bots } = await api.get(`/admin/bots`);
    // Get the bot message ids
    const botMessageIds = botMessagesServer.map(message => message.message_id);

    // Strip the messages to just have the text and section and others
    // Also remove the bot message ids already in the bot
    const messagesStripped = messageListServer
      .filter(message => botMessageIds.includes(message.id) === false)
      .map(message => ({
        id: message.id,
        text: message.text,
        section: message.section,
        type: message.type,
      }));

    const messagesAll = messageListServer.map(message => ({
      id: message.id,
      text: message.text,
      section: message.section,
      type: message.type,
    }));

    // Initialize the messageList column
    const messageList = {
      id: "messageList",
      title: "All Messages",
      messageIds: messagesStripped
        .map(message => message.id)
        .sort((a, b) => a - b),
    };

    const bot = {
      id: "bot",
      title: bots[0].name,
      messageIds: botMessageIds,
    };

    const messageMap = messagesAll.reduce((previous, current) => {
      previous[current.id] = current;
      return previous;
    }, {});

    // Initialize the messages in the entities
    const entities = {
      columnOrder: [messageList.id, bot.id],
      columns: {
        [messageList.id]: messageList,
        [bot.id]: bot,
      },
      messages: messageMap,
    };

    this.setState({ entities });
  }

  onDragStart = start => {
    const id = start.draggableId;
    const selected = this.state.selectedMessageIds.find(
      messageId => messageId === id,
    );

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      this.unselectAll();
    }
    this.setState({
      draggingMessageId: start.draggableId,
    });
  };

  onDragEnd = result => {
    const destination = result.destination;
    const source = result.source;

    // nothing to do
    if (!destination || result.reason === "CANCEL") {
      this.setState({
        draggingMessageId: null,
      });
      return;
    }

    const processed = mutliDragAwareReorder({
      entities: this.state.entities,
      selectedMessageIds: this.state.selectedMessageIds,
      source,
      destination,
    });

    this.setState({
      ...processed,
      draggingMessageId: null,
    });
  };

  onWindowKeyDown = event => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === "Escape") {
      this.unselectAll();
    }
  };

  onWindowClick = event => {
    if (event.defaultPrevented) {
      return;
    }
    this.unselectAll();
  };

  onWindowTouchEnd = event => {
    if (event.defaultPrevented) {
      return;
    }
    this.unselectAll();
  };

  toggleSelection = messageId => {
    const selectedMessageIds = this.state.selectedMessageIds;
    const wasSelected = selectedMessageIds.includes(messageId);

    const newMessageIds = (() => {
      // Message was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [messageId];
      }

      // Message was part of a selected group
      // will now become the only selected item
      if (selectedMessageIds.length > 1) {
        return [messageId];
      }

      // message was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    this.setState({
      selectedMessageIds: newMessageIds,
    });
  };

  toggleSelectionInGroup = messageId => {
    const selectedMessageIds = this.state.selectedMessageIds;
    const index = selectedMessageIds.indexOf(messageId);

    // if not selected - add it to the selected items
    if (index === -1) {
      this.setState({
        selectedMessageIds: [...selectedMessageIds, messageId],
      });
      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedMessageIds];
    shallow.splice(index, 1);
    this.setState({
      selectedMessageIds: shallow,
    });
  };

  // This behaviour matches the MacOSX finder selection
  multiSelectTo = newMessageId => {
    const updated = multiSelect(
      this.state.entities,
      this.state.selectedMessageIds,
      newMessageId,
    );

    if (updated == null) {
      return;
    }

    this.setState({
      selectedMessageIds: updated,
    });
  };

  unselect = () => {
    this.unselectAll();
  };

  unselectAll = () => {
    this.setState({
      selectedMessageIds: [],
    });
  };

  onRemove = messageId => {
    // If messages are selected, remove all the selected messages
    if (
      this.state.selectedMessageIds.length > 0 &&
      this.state.entities.columns["bot"].messageIds.includes(
        this.state.selectedMessageIds[0],
      )
    ) {
      this.removeBotMessages(this.state.selectedMessageIds);
    } else {
      this.removeBotMessages([messageId]);
    }
  };

  removeBotMessages = async messageIds => {
    // Remove messageIds from the bot column messageIds
    const newBotMessageIds = this.state.entities.columns[
      "bot"
    ].messageIds.filter(mId => messageIds.includes(mId) === false);

    this.setState({
      entities: {
        ...this.state.entities,
        columns: {
          ...this.state.entities.columns,
          bot: {
            ...this.state.entities.columns["bot"],
            messageIds: newBotMessageIds,
          },
          messageList: {
            ...this.state.entities.columns.messageList,
            messageIds: [
              ...this.state.entities.columns["messageList"].messageIds,
              ...messageIds,
            ].sort((a, b) => a - b),
          },
        },
      },
    });
  };

  render() {
    const entities = this.state.entities;
    const selected = this.state.selectedMessageIds;

    return (
      <React.Fragment>
        <div className="compose_bot__controls">
          <Button onClick={() => this.saveBot()} text="Save" variant="save" />
        </div>

        <DragDropContext
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
        >
          <Container>
            {entities.columnOrder.map(columnId => (
              <Column
                isBot={columnId === "bot" ? true : false}
                column={entities.columns[columnId]}
                messages={getMessages(entities, columnId)}
                selectedMessageIds={selected}
                key={columnId}
                draggingMessageId={this.state.draggingMessageId}
                toggleSelection={this.toggleSelection}
                toggleSelectionInGroup={this.toggleSelectionInGroup}
                multiSelectTo={this.multiSelectTo}
                onRemove={this.onRemove}
              />
            ))}
          </Container>
        </DragDropContext>
      </React.Fragment>
    );
  }
}
