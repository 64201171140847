import React, { Component } from "react";
import styled from "styled-components";
import memoizeOne from "memoize-one";
import { Droppable } from "react-beautiful-dnd";
import { grid, colors, borderRadius } from "util/constants.js";
import Message from "./Message";

const Container = styled.div`
  width: 600px;
  margin: ${grid}px;
  border-radius: ${borderRadius}px;
  border: 1px solid ${colors.grey.dark};
  background-color: ${colors.grey.medium};
  /* we want the column to take up its full height */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Title = styled.div`
  margin-left: 10px;
  /*font-weight: bold;*/
  padding: ${grid}px;
`;

const MessageList = styled.div`
  padding: ${grid}px;
  min-height: 200px;
  flex-grow: 1;
  transition: background-color 0.2s ease;
  ${props =>
    props.isDraggingOver ? `background-color: ${colors.grey.darker}` : ""};
`;

const getSelectedMap = memoizeOne(selectedMessageIds =>
  selectedMessageIds.reduce((previous, current) => {
    previous[current] = true;
    return previous;
  }, {}),
);

export default class Column extends Component {
  onRemove = messageId => event => {
    this.props.onRemove(messageId);
  };

  render() {
    const keepOrder = this.props.isBot;
    const column = this.props.column;
    const messages = this.props.messages.sort((a, b) =>
      !keepOrder ? a.id - b.id : true,
    );

    const selectedMessageIds = this.props.selectedMessageIds;

    const draggingMessageId = this.props.draggingMessageId;
    return (
      <Container>
        <Title>{column.title}</Title>
        <Droppable droppableId={column.id}>
          {(provided, snapshot) => (
            <MessageList
              ref={provided.innerRef}
              isDraggingOver={snapshot.isDraggingOver}
              {...provided.droppableProps}
              style={{ overflowY: "auto" }}
            >
              {messages.map((message, index) => {
                const isSelected = Boolean(
                  getSelectedMap(selectedMessageIds)[message.id],
                );
                const isGhosting =
                  isSelected &&
                  Boolean(draggingMessageId) &&
                  draggingMessageId !== message.id;
                return (
                  <Message
                    message={message}
                    index={index}
                    key={message.id}
                    isSelected={isSelected}
                    isGhosting={isGhosting}
                    selectionCount={selectedMessageIds.length}
                    toggleSelection={this.props.toggleSelection}
                    toggleSelectionInGroup={this.props.toggleSelectionInGroup}
                    multiSelectTo={this.props.multiSelectTo}
                    showRemoveIcon={this.props.isBot}
                    onRemove={this.onRemove(message.id)}
                  />
                );
              })}
              {provided.placeholder}
            </MessageList>
          )}
        </Droppable>
      </Container>
    );
  }
}
