import React from "react";

class FeedbackRow extends React.Component {
  render() {
    return (
      <>
        <td>{this.props.feedback.message.order}</td>
        <td>{this.props.feedback.message.text}</td>
        <td>{this.props.feedback.do_not_understand_count}</td>
        <td>{this.props.feedback.answers_do_not_apply_count}</td>
        <td>{this.props.feedback.want_to_choose_more_answers_count}</td>
        <td>{this.props.feedback.question_could_be_better_count}</td>
        <td>
          {this.props.feedback.free_text_responses.map((line, idx) => (
            <p key={idx}>
              ({line.user_id}) {line.email}: {line.free_text}
            </p>
          ))}
        </td>
      </>
    );
  }
}

export default FeedbackRow;
