import "./admin_login.scss";

import React from "react";
import api from "util/api.js";

import Input from "components/Input/Input.js";
import Logo from "images/protu-character-waving.svg";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: null,
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    api
      .post("/admin/login", {
        email: this.state.email,
        password: this.state.password,
      })
      .then(result => {
        if (result.data.success === true) {
          window.localStorage.setItem("jwt", result.data.access_token);
          window.localStorage.setItem("user", JSON.stringify(result.data.user));
          window.location = "/";

          return;
        }

        this.setState({ error: result.data.error });
      })
      .catch(error => {
        this.setState({ error: error.message });
      });
  };

  render() {
    return (
      <div className="admin_login">
        <form className="admin_login__form" onSubmit={this.handleSubmit}>
          <img className="admin_login__logo" alt="Logo" src={Logo} />
          <div className="admin_login__header">Admin Login</div>
          <Input
            label="Email"
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
            autoComplete="email"
            width="fill"
            required
            autofocus
          />
          <Input
            label="Password"
            name="password"
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
            autoComplete="current-password"
            width="fill"
            required
          />
          <Input type="submit" value="Login" width="fill" />
          {this.state.error && (
            <div className="admin_login__error">{this.state.error}</div>
          )}
        </form>
      </div>
    );
  }
}

export default Login;
