import React, { Component } from "react";
import "./bot_button.scss";
import cn from "classnames";
import Icon from "components/Icon/Icon.js";
import PropTypes from "prop-types";
import requiredIf from "react-required-if";

class Button extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.on_mount_emit_ref && this.props.choice_id) {
      this.props.on_mount_emit_ref(this.props.choice_id, this.ref);
    }
  }

  componentWillUnmount() {
    if (this.props.on_unmount_delete_ref && this.props.choice_id) {
      this.props.on_unmount_delete_ref(this.props.choice_id);
    }
  }

  format_highlighted_text = () => {
    let highlighted_text = "";

    if (
      this.props.hasOwnProperty("text_highlight") &&
      !!this.props.text_highlight
    ) {
      let text_highlight = this.props.text_highlight,
        text_highlight_lowercase = text_highlight.toLowerCase(),
        text = this.props.text,
        text_lowercase = text.toLowerCase(),
        text_highlight_starts = text_lowercase.indexOf(
          text_highlight_lowercase,
        );

      highlighted_text =
        text.slice(0, text_highlight_starts) +
        '<span class="button__button_caption_highlight">' +
        text.slice(
          text_highlight_starts,
          text_highlight_starts + text_highlight.length,
        ) +
        "</span>" +
        text.slice(text_highlight_starts + text_highlight.length);
    } else {
      highlighted_text = this.props.text;
    }

    return { __html: highlighted_text };
  };

  render() {
    let button_caption = this.format_highlighted_text();

    return (
      <button
        type="button"
        className={cn("button", {
          "button--protu_purple_dark": this.props.colour === "purple_dark",
          "button--protu_navy": this.props.colour === "navy",
          "button--grey_superdark": this.props.colour === "grey_superdark",
          "button--grey_mid": this.props.colour === "grey_mid",
          "button--transparent": this.props.variant === "transparent",
          "button--hollow": this.props.variant === "hollow",
          "button--solid": this.props.variant === "solid",
          "button--small": this.props.size === "small",
          "button--is_fake": this.props.fake,
        })}
        ref={this.ref}
        onClick={this.props.on_click}
        disabled={this.props.is_disabled}
      >
        {this.props.icon && this.props.icon_position === "left" && (
          <Icon src={this.props.icon} size={12} />
        )}

        {this.props.text && (
          <span
            className="button__button_caption"
            dangerouslySetInnerHTML={button_caption}
          />
        )}

        {this.props.icon && this.props.icon_position === "right" && (
          <Icon src={this.props.icon} size={12} />
        )}
      </button>
    );
  }
}

Button.propTypes = {
  animation_name: PropTypes.string,
  colour: PropTypes.oneOf(["purple_dark", "navy", "grey_dark", "grey_mid"]),
  variant: PropTypes.oneOf(["transparent", "hollow", "solid"]),
  size: PropTypes.oneOf(["standard", "small"]),
  fake: PropTypes.bool,
  text: requiredIf(PropTypes.string, props => !props.hasOwnProperty("icon")),
  text_highlight: PropTypes.string,
  icon: PropTypes.string,
  icon_position: PropTypes.oneOf(["left", "right"]),
  on_mount_emit_ref: PropTypes.func,
  on_click: PropTypes.func,
  on_unmount_delete_ref: PropTypes.func,
  choice_id: requiredIf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    props =>
      props.hasOwnProperty("on_mount_emit_ref") ||
      props.hasOwnProperty("on_unmount_delete_ref"),
  ),
  is_selected: PropTypes.bool,
  is_disabled: PropTypes.bool,
};

Button.defaultProps = {
  classNames: [],
  colour: "grey_superdark",
  variant: "transparent",
  size: "standard",
  fake: false,
  icon_position: "left",
  is_selected: false,
  is_disabled: false,
};

export default Button;
