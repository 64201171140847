export const messageTypes = [
  "yes / no",
  "conversational",
  "free text",
  "multiple choice",
  "search",
  "single choice",
  "slider",
];

export const colors = {
  blue: {
    deep: "rgb(0, 121, 191)",
    light: "lightblue",
    lighter: "#d9fcff",
    soft: "#E6FCFF",
  },
  black: "#4d4d4d",
  shadow: "rgba(0,0,0,0.2)",
  grey: {
    darker: "#C1C7D0",
    dark: "#E2E4E6",
    medium: "#DFE1E5",
    N30: "#EBECF0",
    light: "#F4F5F7",
  },
  green: "rgb(185, 244, 188)",
  white: "white",
  purple: "rebeccapurple",
  orange: "#FF991F",
};

export const grid = 4;

export const borderRadius = 8;

export const BOT_ID = 1;

export const INDIVIDUAL_TRAITS_MODEL_ID = 1;
