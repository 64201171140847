import "./correlations.scss";

import React from "react";
import api from "util/api.js";
import { BOT_ID } from "util/constants.js";

class Correlations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      correlations: this.create2DArray(0),
      corrSize: 0,
    };
  }

  componentDidMount = async () => {
    const { data: correlationsFlat } = await api.get(
      `/admin/get-correlations/?botId=` + BOT_ID,
    );

    if (correlationsFlat) {
      // The first element conveys the size of the array...
      const size = correlationsFlat.shift().size;
      const correlations = this.create2DArray(size);

      // Can sort the columns here
      correlationsFlat.sort((a, b) =>
        this.sortArrayByMarkerThenSubmarker(
          a.marker1_id,
          b.marker1_id,
          a.submarker1_name,
          b.submarker1_name,
        ),
      );

      for (let i = 0; i < size; i++) {
        for (let j = 0; j < size; j++) {
          correlations[i][j] = correlationsFlat[i * size + j];
        }
      }

      for (let i = 0; i < size; i++) {
        // Sort the rows
        correlations[i].sort((a, b) =>
          this.sortArrayByMarkerThenSubmarker(
            a.marker2_id,
            b.marker2_id,
            a.submarker2_name,
            b.submarker2_name,
          ),
        );
      }

      this.setState({
        correlations,
        corrSize: size,
      });
    }
  };

  create2DArray(rows) {
    let arr = [];

    for (let i = 0; i < rows; i++) {
      arr[i] = [];
    }

    return arr;
  }

  sortArrayByMarkerThenSubmarker(marker1, marker2, submarker1, submarker2) {
    if (marker1 === marker2) {
      if (submarker1 < submarker2) {
        return -1;
      } else {
        return 1;
      }
    }

    return marker1 - marker2;
  }

  ModelRow = ({ correlationCoefficient, idx1, idx2 }) => {
    const highlighted = {
      background: this.getHSLA(1.0, -1.0, correlationCoefficient, 0, 360),
    };

    return (
      <>
        {idx2 === 0 ? (
          <>
            <th>
              <span>{this.state.correlations[idx1][idx2].submarker1_name}</span>
            </th>
          </>
        ) : null}
        {idx1 !== idx2 ? (
          <td className="coefficients" align="right" style={highlighted}>
            <span>
              <strong>{correlationCoefficient.toFixed(2)}</strong>
            </span>
          </td>
        ) : (
          <td className="coefficients" align="center">
            <span>{"-"}</span>
          </td>
        )}
      </>
    );
  };

  //Change the start and end values to reflect the hue map
  //Reference : http://www.ncl.ucar.edu/Applications/Images/colormap_6_3_lg.png
  /*
  Quick ref:
      0 – red
      60 – yellow
      120 – green
      180 – turquoise
      240 – blue
      300 – pink
      360 – red
  */
  getHSLA(maxval, minval, val, start, end) {
    const a = (val - minval) / (maxval - minval),
      b = (end - start) * a,
      c = b + start;

    // Return a CSS HSLA (Hue Saturation Level Alpha) string
    return "hsla(" + c + ", 100%, 50%, 50%)";
  }

  render() {
    return this.state.correlations && this.state.correlations[0] ? (
      <table className="table-hover-row-correlations table-hover-column-correlations">
        <thead>
          <tr>
            <th />
            {this.state.correlations[0].map((corr, idx) => (
              <th key={idx}>
                <span className="correlations__submarker_name">
                  {corr.submarker2_name}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {this.state.correlations.map((coeffArray, idx1) => (
            <tr key={idx1}>
              {coeffArray.map((coeff, idx2) => (
                <this.ModelRow
                  correlationCoefficient={coeff.correlation_coefficient}
                  idx1={idx1}
                  idx2={idx2}
                  key={(idx1 + 1) * (idx2 + 1)}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    ) : null;
  }
}

export default Correlations;
