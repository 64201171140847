import "./Messages.scss";

import React from "react";
import _ from "lodash";

import api from "util/api.js";
import { messageTypes } from "util/constants.js";
import Select from "components/Select/Select.js";

import Bot from "components/Bot/Bot.js";
import EditMessage from "old-components/EditMessage/EditMessage.js";
import EditMessageModal from "old-components/EditMessageModal/EditMessageModal.js";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      editingMessage: null,
      filters: {
        type: "any",
        section: "any",
      },
    };
  }

  componentDidMount() {
    this.getMessages();
    this.setState({
      filters: {
        ...this.state.filters,
        section: localStorage.getItem("section") || "any",
        type: localStorage.getItem("type") || "any",
      },
    });
  }

  async getMessages() {
    const { data: messages } = await api.get(`/admin/messages`);
    this.setState({ messages });
  }

  onMessageChange = message => {
    this.setState({
      editingMessage: message,
    });
  };

  refreshEditingMessage = async () => {
    const { data: editingMessage } = await api.get(
      `/admin/message/${this.state.editingMessage.id}`,
    );
    this.setState({ editingMessage });
  };

  validateForm = () => {};

  saveMessage = async () => {
    await api.put(
      `/admin/message/${this.state.editingMessage.id}`,
      this.state.editingMessage,
    );

    this.setState({
      editingMessage: null,
      messages: this.state.messages.map(m =>
        m.id === this.state.editingMessage.id ? this.state.editingMessage : m,
      ),
    });
  };

  deleteMessage = () => {
    const result = window.confirm("Are you sure?");
    if (result === false) {
      return;
    }

    // TODO: Avoid duplicating the order decrement on the client.
    api.delete(`/admin/message/${this.state.editingMessage.id}`).then(() => {
      this.setState({
        editingMessage: null,
        messages: this.state.messages.filter(
          m => m.id !== this.state.editingMessage.id,
        ),
      });

      this.getMessages();
    });
  };

  newMessage = async (idx, e) => {
    // Don't do the row click.
    e.stopPropagation();

    const message = {
      order: this.state.messages[idx].order,
    };

    const { data: editingMessage } = await api.post("/admin/message", message);
    this.getMessages();
    this.setState({ editingMessage });
  };

  visibleMessages() {
    return this.state.messages.filter(
      m =>
        (this.state.filters.type === "any" ||
          m.type === this.state.filters.type) &&
        (this.state.filters.section === "any" ||
          m.section === this.state.filters.section),
    );
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  onDragEnd = result => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const sourceMessage = this.state.messages[result.source.index];
    const destinationMessage = this.state.messages[result.destination.index];

    // Update it before POSTing to prevent glitchy UX.
    const messages = this.reorder(
      this.state.messages,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      messages,
    });

    api
      .post("/admin/actions/move-message", {
        messageId: sourceMessage.id,
        newOrder: destinationMessage.order,
      })
      .then(res => {
        this.getMessages();
      });
  };

  editMessage = message => {
    this.setState({ editingMessage: message });
  };

  setFilter(attr, value) {
    localStorage.setItem(attr, value);
    this.setState({
      filters: {
        ...this.state.filters,
        [attr]: value,
      },
    });
  }

  MessageRow = ({ message }) => {
    return (
      <>
        <td className="table__cell">{message.order}</td>
        <td className="table__cell">{message.section}</td>
        <td className="table__cell">{message.type}</td>
        <td className="table__cell">{message.text}</td>
      </>
    );
  };

  render() {
    if (this.state.messages.length === 0) {
      return <div>There are no messages!</div>;
    }

    return (
      <div>
        <div className="messages__filters">
          <Select
            label="Section"
            name="section"
            value={this.state.filters.section}
            onChange={e => this.setFilter("section", e.target.value)}
            options={[
              "any",
              ..._.uniqBy(this.state.messages, "section").map(
                message => message.section,
              ),
            ]}
            inline={true}
          />
          <Select
            label="Type"
            name="type"
            value={this.state.filters.type}
            onChange={e => this.setFilter("type", e.target.value)}
            options={["any", ...messageTypes]}
            inline={true}
          />
        </div>

        {this.state.editingMessage !== null && (
          <EditMessageModal>
            <div className="messages__modal_column">
              <Bot message={this.state.editingMessage} useRawText={true} />
            </div>
            <div className="messages__modal_column">
              <EditMessage
                onMessageChange={this.onMessageChange}
                refreshEditingMessage={this.refreshEditingMessage}
                message={this.state.editingMessage}
                close={() => this.setState({ editingMessage: null })}
                delete={this.deleteMessage}
                save={this.saveMessage}
                messages={this.state.messages}
              />
            </div>
          </EditMessageModal>
        )}

        <DragDropContext onDragEnd={this.onDragEnd}>
          <table className="table">
            <thead className="table__head">
              <tr className="table__head-row">
                <th className="table__head-cell">#</th>
                <th className="table__head-cell">Section</th>
                <th className="table__head-cell">Type</th>
                <th className="table__head-cell">Text</th>
                <th className="table__head-cell" />
              </tr>
            </thead>
            <Droppable droppableId="messages">
              {droppableProvided => (
                <tbody
                  ref={ref => {
                    droppableProvided.innerRef(ref);
                  }}
                  {...droppableProvided.droppableProps}
                >
                  {this.visibleMessages().map((message, idx) => (
                    <Draggable
                      draggableId={message.id}
                      index={idx}
                      key={message.id}
                    >
                      {(provided, snapshot) => (
                        <tr
                          onClick={() => this.editMessage(message)}
                          ref={provided.innerRef}
                          className="table__row"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <this.MessageRow message={message} />
                          <td className="table__cell messages__table-cell-new-message">
                            <span
                              className="messages__new-message"
                              onClick={this.newMessage.bind(this, idx + 1)}
                            >
                              +&nbsp;new
                            </span>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>
    );
  }
}

export default Messages;
